<template>
    <FadeInOut entry="left" exit="right" :duration="350" mode="out-in">
        <div :class="{
            'vue-alert': true,
            'alert': severity == 'alert',
            'info': severity == 'info',
            'ok': severity == 'ok'
        }" v-show="internalVisible">
            <span v-if="closable" class="closebtn" @click="(internalVisible = false)">&times;</span>
            {{message}}
        </div>
    </FadeInOut>
</template>

<script>
export default {
    props: { 
        message: {
            type: String,
            default: ""
        },
        visible: {
            required: true
        },
        closable: {
            type: Boolean,
            default: true
        },
        severity: {
            type: String,
            default: 'alert'
        }
    },
    data() {
        return {
            internalVisible: this.visible
        }
    },
    watch: {
        visible(val) {
            this.internalVisible = val;
        },
        internalVisible(val) {
            this.$emit('update:modelValue', val);
        }
    }
}
</script>

<style>
/* The alert message box */
.vue-alert {
    width: 100%;
}

.alert {
  padding: 20px;
  background-color: #f44336; /* Red */
  color: white;
  margin-bottom: 15px;
}

.ok {
  padding: 20px;
  background-color: greenyellow; /* Red */
  color: black;
  margin-bottom: 15px;
}

.info {
  padding: 20px;
  background-color: blue; /* Red */
  color: black;
  margin-bottom: 15px;
}

/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}
</style>