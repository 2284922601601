import { BasicRoute } from './BasicRoute'

export default [
    new BasicRoute('/inventarios/catalogos/almacen', 'almacen', () => import('../pages/Inventarios/Catalogos/InventoryWareHouse.vue'), 'Inventarios', 'Almacen'),
    //new BasicRoute('/inventarios/catalogos/movementtype', 'tipomovimiento', () => import('../pages/Inventarios/Catalogos/MovementType.vue'), 'Inventarios', 'Tipos de Movimientos'),
    //new BasicRoute('/inventarios/catalogos/documenttype', 'documenttype', () => import('../pages/Inventarios/Catalogos/DocumentType.vue'), 'Inventarios', 'Tipos de Documentos'),
    new BasicRoute('/inventarios/Catalogos/inventory_subwarehouse', 'inventory_subwarehouse', () => import('../pages/Inventarios/Catalogos/InventorySubWareHouse.vue'), 'Inventarios', 'SubAlmacen'),
    new BasicRoute('/inventarios/catalogos/inventory_measure_unit', 'inventory_measure_unit', () => import('../pages/Inventarios/Catalogos/InventoryMeasureUnit.vue'), 'Inventarios', 'Unidad de Medida'),
    new BasicRoute('/inventarios/inventory_family', 'inventory_family', () => import('../pages/Inventarios/Catalogos/InventoryFamily.vue'), 'Inventarios', 'Familia'),
    new BasicRoute('/inventarios/inventory_group', 'inventory_group', () => import('../pages/Inventarios/Catalogos/InventoryGroup.vue'), 'Inventarios', 'Grupo'),
    new BasicRoute('/inventarios/inventory_subgroup', 'inventory_subgroup', () => import('../pages/Inventarios/Catalogos/InventorySubGroup.vue'), 'Inventario', 'SubGrupo'),
    new BasicRoute('/inventarios/catalogos/subalmacen', 'subalmacen', () => import('../pages/Inventarios/Catalogos/InventorySubWareHouse.vue'), 'Inventarios', 'SubAlmacen'),
    new BasicRoute('/inventarios//inventory_family', 'inventory_family', () => import('../pages/Inventarios/Catalogos/InventoryFamily.vue'), 'Inventarios', 'Familia'),
    //new BasicRoute('/Inventarios/catalogos/inventory_group', 'inventory_group', () => import('../pages/Inventarios/Catalogos/InventoryGroup.vue'), 'Inventarios', 'Grupo'),
    //new BasicRoute('/Inventarios/catalogos/inventory_subgroup', 'inventory_subgroup', () => import('../pages/Inventarios/Catalogos/InventorySubGroup.vue'), 'Inventario', 'SubGrupo'),
    new BasicRoute('/inventarios/inventory_article', 'inventory_article', () => import('../pages/Inventarios/Catalogos/InventoryArticle.vue'), 'Inventory', 'Articulo'),
    new BasicRoute('/inventarios/generador', 'inventory_generator', () => import('../../src/components/general/BarcodeGenerator.vue'), 'Inventory', 'Codigo de Barra'),
    new BasicRoute('/inventarios/catalogos/movimiento', 'inventory_movement', () => import('../pages/Inventarios/Catalogos/InventoryMovement.vue'), 'Inventory', 'Movimientos Inventario'),
    new BasicRoute('/inventarios/AdminMov', 'Administrador de Movimientos', () => import('../pages/Inventarios/Herramientas/AdminMovementWarehouse.vue'), 'Inventory', 'Administrar Movimientos'),
    new BasicRoute('/inventarios/prueba', 'inventory_prueba', () => import('../pages/Inventarios/Catalogos/PruebaComponentes.vue'), 'Inventory', 'Movimientos Inventario') ,
    new BasicRoute('/inventarios/inventory_configuration','inventory_configuration',() => import('../pages/Inventarios/Catalogos/InventoryConfiguration.vue'),'Inventory', 'inventory_configuration') ,
    new BasicRoute('/inventarios/inventorytransfer','inventory_transfer',() => import('../pages/Inventarios/Aplicaciones/TransferWarehouse.vue'),'Inventory', 'Tranferencia entre almacenes') ,
    new BasicRoute('/inventarios/inventoy_max_min','inventoy_max_min',() => import('../pages/Inventarios/Catalogos/InventoryMaxMin.vue'),'Inventory', 'inventoy_max_min') ,
    new BasicRoute('/inventarios/inventory_adjust','inventoy_adjust',() => import('../pages/Inventarios/Aplicaciones/InventoryAdjusment.vue'),'Inventory', 'inventory_adjust'),
    new BasicRoute('/inventarios/movementwarehouse', 'inventory_MovementWarehouse', () => import('../pages/Inventarios/Catalogos/InventoryMovementWarehouse.vue'), 'Inventory', 'Movimientos de Almacen'),
    //new BasicRoute('/inventarios/catalogos/attribute','inventory_attribute',() => import('../pages/Inventarios/Catalogos/InventoryAttribute.vue'),'Inventory', 'Atributo')
    //new BasicRoute('/inventarios/catalogos/attribute','inventory_attribute',() => import('../pages/Inventarios/Catalogos/InventoryAttribute.vue'),'Inventory', 'Atributo')	
    new BasicRoute('/inventarios/entradasalidaalmacen','inventoy_adjust',() => import('../pages/Inventarios/Aplicaciones/EntryExitWareHouse.vue'),'Inventory', 'Entrada/Salida de Almacén'),
]
    
    

