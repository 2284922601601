<template>
  <div class="grid crud-demo">
    <Loader v-model="loading" />
    <div class="col-12">
      <div class="card">
        <Toast />
        <div class="grid formgrid p-fluid">
          <div class="col-12">
            <Fieldset :toggleable="true" legend="Parametros">
              <div class="p-fluid formgrid grid">
                <FormDropdown :wrapperClass="'col-4 field'" label="Tipo" :options="types" :optionLabel="'name'"
                  :optionValue="'id'" v-model="selectType" />
                <FormDropdown :wrapperClass="'col-8 field'" label="Reporte" v-model="selectReport" :options="reportsByType"
                  :optionLabel="'title'" :optionValue="'id'" />
                <FormDropdown :wrapperClass="'col-12 field'" label="Tipo Gráfica" v-model="selectGraphs" :options=graph
                  :optionLabel="'text'" :optionValue="'id'" @change="graphicType"/>
              </div>
          </Fieldset>
            <Fieldset :toggleable="true" legend="Parametros" v-if="inputs.length > 0">
              <div class="grid formgrid p-fluid">
                <div class="col-6" v-for="input in inputs" :key="input.name">
                  <FormInputText v-if="input.type == 'INPUT'" :label="input.name" v-model="input.value" />
                  <FormInputSwitch :wrapperClass="'col-12 field'" v-else-if="input.type == 'BOOLEAN'" :label="input.name"
                    v-model="input.value" />
                  <FormCalendar v-else-if="input.type == 'DATE'" :wrapperClass="'col-12 field'" :label="input.name"
                    v-model="input.value" />
                  <FormCalendar :showSeconds="true" :wrapperClass="'col-12 field'" :showTime="true"
                    v-else-if="input.type == 'DATETIME'" :label="input.name" v-model="input.value" />
                  <FormInputText :disabled="true" v-else-if="input.type == 'SESSION'"
                    :label="input.name == 'COMPANY' ? 'Empresa' : input.name == 'BRANCH' ? 'Sucursal' : input.name == 'USER' ? 'Usuario' : input.name"
                    v-model="input.session" />
                  <FormDropdown v-else-if="input.type == 'DROPDOWN'" :label="input.name" :optionLabel="input.dd_label"
                    :options="input.data" :optionValue="input.dd_value" v-model="input.value" />
                </div>
              </div>
            </Fieldset>
            <br>
          </div>
          <div v-if="chart_type != ''" class="col-12">
            <Fieldset :toggleable="true" legend="Grafica">
              <div class="grid formgrid p-fluid">
                <div class="col-9">
                  <Chart v-show="datasets.length > 0 || (datasets_number == 1 && rows.length > 0)" ref="pie"
                    :width="chart_width" :height="chart_height" :options="options" :type="chart_type"
                    :data="chartData" />
                </div>
                <div class="col-3" v-if="datasets_number == 0">
                  <TabView ref="tabview1">
                    <TabPanel v-for="tab_idx in datasets_number" :key="'tab_' + tab_idx"
                      :header="datasets_name[tab_idx - 1]">
                      <BasicDatatable :key="'dt_chart_' + tab_idx" :currentPageReportTemplate="''" :headerVisible="false"
                        :gridlines="true" :rows="datasets[tab_idx - 1]" :headers="headers" />
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </Fieldset>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { SQLQuery } from "../../../../models/general/SQLQuery";
import {
  HeaderGrid,
  Rule,
  ErrorToast,
  randomColor,
} from "../../../../utilities/General";
import Loader from "../../../../components/general/Loader.vue";
import Session from "../../../../mixins/sessionMixin";
import FormDropdown from "../../../../components/general/FormDropdown.vue";
import FormInputText from "../../../../components/general/FormInputText.vue";
import BasicDatatable from '../../../../components/general/BasicDatatable.vue';
import FormInputSwitch from "../../../../components/general/FormInputSwitch.vue";
import FormCalendar from "../../../../components/general/FormCalendar.vue";
import { Branch } from '../../../../models/general/Branch';
import { Company } from '../../../../models/general/Company';
import { SQLQueryType } from '../../../../models/general/SQLQueryType';
import { BasicSimpleChart, BasicMultipleChart, BasicDataset } from "../../../../utilities/BasicChart";
export default {
  mixins: [Session],
  props: {
    id: null,
    view: null
  },
  data() {
    return {
      datasets_name: [],
      datasets: [],
      datasets_number: 1,
      chart_width: 600,
      chart_height: 600,
      options: {
        responsive: false,
        hoverMode: 'index',
        stacked: false
      },
      selectReport: null,
      selectType: null,
      selectGraphs: null,
      inputs: [],
      report: null,
      chart_type: '',
      entities: [],
      chart_data: '',
      chart_label: '',
      uploadURL: null,
      newDialog: false,
      deleteDialog: false,
      filters: {},
      headers: [],
      branch: {},
      company: {},
      rows: [],
      rules: [
        new Rule({ name: "title" }),
        new Rule({ name: "query" })
      ],
      graph: [],
      validate: {
        valid: false,
        validations: {
          name: null,
          key_name: null,
        },
      },
      loading: false,
    };
  },
  watch: {
    chartData() {
      if (this.$refs.pie) {
        this.$refs.pie.refresh();
      }
    },
    async selectReport(newValue) {
      if (newValue) {
        try {
          this.rows = [];
          this.datasets = [];
          this.loading = true;
          let report = new SQLQuery(this.session);
          report.id = newValue;
          let response = await report.getReport();
          this.inputs = response.inputs;
          this.chart_type = response.chart_type ?? '';
          this.chart_label = response.chart_label_column ?? '';
          this.chart_data = response.chart_data_column ?? '';

          this.inputs.forEach(input => {
            input.value = input.type == 'BOOLEAN' ? false
              : input.type == "DATE" || input.type == "DATETIME" ? new Date()
                : input.type == "INPUT" ? ''
                  : input.type == "SESSION" ?
                    (input.name == "COMPANY" ? this.company.id
                      : input.name == "BRANCH" ? this.branch.id
                        : input.name == "USER" ? this.session.user
                          : null)
                    : null;
            input.session = input.type == "SESSION" ?
              (input.name == "COMPANY" ? this.company.name
                : input.name == "BRANCH" ? this.branch.name
                  : input.name == "USER" ? this.session.user
                    : null)
              : null;
            if (input.data)
              input.data = JSON.parse(input.data);
          });
          this.list();
        } catch (error) {
          this.$toast.add(new ErrorToast(error));
        } finally {
          this.loading = false;
        }
      }
    }
  },
  computed: {

    reportsByType() {
      return this.selectType == null ? this.entities : this.entities.filter(x => x.id_sql_query_type == this.selectType)
    },
    graphs() {
      return this.selectGraphs == this.graph
    },
    chartData() {
      if (this.datasets_number == 1) {
        if (this.rows.length > 0) {
          let labels = [];
          let data = [];
          let backgroundColor = [];
          this.rows.forEach(row => {
            labels.push(row[this.chart_label]);
            data.push(row[this.chart_data]);
            backgroundColor.push(randomColor());
            console.log(JSON.stringify(backgroundColor))
          })
          return new BasicSimpleChart(labels, this.datasets_name[0], backgroundColor, backgroundColor);
        }
        else
          return new BasicSimpleChart(['A', 'B', 'C'], [300, 50, 100], ["#42A5F5", "#66BB6A", "#FFA726"], ["#64B5F6", "#81C784", "#FFB74D"]);
      } else {
        if (this.datasets.length > 0) {
          let myChart = new BasicMultipleChart();
          this.datasets[0].forEach(row => {
            myChart.labels.push(row[this.chart_label]);
          })
          let index = 0;
          this.datasets.forEach(dataset => {
            let myDataset = new BasicDataset(this.datasets_name[index]);
            let myColor = randomColor();
            dataset.forEach(row => {
              myDataset.data.push(row[this.chart_data]);
              myDataset.backgroundColor.push(myColor);
            });
            myChart.datasets.push(myDataset);
            index++;
          });
          return myChart;
        }
        else
          return new BasicSimpleChart(['A', 'B', 'C'], [300, 50, 100], ["#42A5F5", "#66BB6A", "#FFA726"], ["#64B5F6", "#81C784", "#FFB74D"]);
      }
    }
  },
  components: { FormCalendar, FormInputText, FormInputSwitch, Loader, BasicDatatable, FormDropdown },
  created() {
    this.entity = new SQLQuery(this.session);
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    graphicType(payload){
      console.log(JSON.stringify(payload.toLowerCase()))
      this.chart_type=payload
    },
    restart() {
      this.datasets = [];
      this.datasets_number = 1;
      this.chart_type = '';
      this.selectType = null;
      this.selectReport = null;
      this.inputs = [];
      this.report = null;
      this.headers = [];
      this.rows = [];
    },
    async list() {
      this.loading = true;
      try {
        let report = new SQLQuery(this.session);
        report.id = this.selectReport;
        report.inputs = [];
        this.inputs.forEach(input => {
          let x = { ...input };
          if (x.data)
            delete x.data;
          report.inputs.push(x);
        });
        report.inputs.forEach(input => {
          input.value = input.type == "DATE" || input.type == "DATETIME" ? input.value.toISOString() : input.value.toString();
        });
        let response = await report.setReport();
        this.headers = [];
        response.headers.forEach(header => {
          this.headers.push(new HeaderGrid(header.name, header.name, {
            type: header.type == "Int32" ? "integer"
              : header.type == "String" ? "text"
                : header.type == "Boolean" ? "boolean"
                  : header.type == "DateTime" ? "date-time"
                    : "undefined"
          }))
        });
        if ((response.chart_datasets ?? 1) == 1) {
          this.rows = JSON.parse(response.result);
          this.datasets_name = response.chart_datasets_name;
        } else {
          this.datasets = [];
          let data = JSON.parse(response.result);
          for (let i = 0; i < response.chart_datasets; i++) {
            this.datasets.push(data["table_" + i]);
          }
          this.datasets_number = response.chart_datasets;
          this.datasets_name = response.chart_datasets_name;
        }
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      try {
        this.entities = await this.entity.allByProfile();
        let branch = new Branch();
        branch.id = this.session.branch;
        this.branch = await branch.retrieve();
        let company = new Company();
        company.id = this.session.company;
        this.company = await company.retrieve();
        this.types = await new SQLQueryType(this.session).all();
        this.graph = [
        { id: '', text: 'SIN GRAFICA' },
        { id: 'pie', text: 'PASTEL' },
        { id: 'doughnut', text: 'DONA' },
        { id: 'bar', text: 'BARRAS' },
        { id: 'line', text: 'LINEAS' },
        { id: 'polarArea', text: 'POLAR' },
        { id: 'radar', text: 'RADAR' }
      ],
        this.selectType = 1;
        this.selectReport = 2;

        if (this.id) {
          alert("PENDIENTE" + this.id);
        }
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
  
<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollable-table::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.scrollable-table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50px;
}

.scrollable-table::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

.scrollable-table::-webkit-scrollbar-thumb:hover {
  background: #555;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2)
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}</style>
  