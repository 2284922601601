import { BasicRoute } from "./BasicRoute";
import { ParentRoute } from "../routes/ParentRoute";

export default [
    new ParentRoute('/compras', 'Compras', () => import('../components/templates/ParentDirectory.vue')),
    new BasicRoute('/Compras/catalogos/TipoRequisicion', 'Tipo_Requisicion', () => import('../pages/Compras/Catalogos/ShoppingRequisitionType.vue'), 'Compras', 'Tipo de Requisicion'),
    new BasicRoute('/Compras/Aplicaciones/Requisicion', 'Requisicion', () => import('../pages/Compras/Aplicaciones/ShoppingRequistion.vue'), 'Compras', 'Requisicion'),
    new BasicRoute('/Compras/catalogos/lab', 'LAB', () => import('../pages/Compras/Catalogos/LAB.vue'), 'Compras', 'LAB'),
    new BasicRoute('/Compras/aplicaciones/purchaseorder', 'orden_compra', () => import('../pages/Compras/Aplicaciones/PurchaseOrder.vue'), 'Compras', 'Orden de Compra'),
    new BasicRoute('/Compras/Aplicaciones/AdministradorRequisicion', 'Administrador_Requisicion', () => import('../pages/Compras/Aplicaciones/ShoppingRequisitionAdmin.vue'), 'Compras', 'Administrador de Requisicion'),
]

