import { BasicRoute } from "../routes/BasicRoute";
import { ParentRoute } from "../routes/ParentRoute";

export default [
    new ParentRoute('/general', 'General', () => import('../components/templates/ParentDirectory.vue')),
    new BasicRoute('/general/catalogos/empresa','empresa',() => import('../pages/General/Catalogos/Company.vue'),'General', 'Empresa'),
    new BasicRoute('/general/catalogos/sucursal','sucursal',() => import('../pages/General/Catalogos/Branch.vue'),'General', 'Sucursal'),
    new BasicRoute('/general/herramientas/generadorreportes','generadorreportes',() => import('../pages/General/Herramientas/ReportManager.vue'),'General', 'Generador de Reportes'),
    new BasicRoute('/general/herramientas/reporteador','reporteador',() => import('../pages/General/Herramientas/ReportViewer.vue'),'General', 'Visor de Reportes'),
    new BasicRoute('/general/configuracion/correo','configuracorreo',() => import('../pages/General/Configuracion/Email.vue'),'General', 'Configuracion de Correo'),
    new BasicRoute('/general/catalogos/tiporeporte','tiporeporte',() => import('../pages/General/Catalogos/ReportType.vue'),'General', 'Tipo de Reporte'),
    new BasicRoute('/general/catalogos/generateCode','generateCode',() => import('../pages/General/Catalogos/GenerateCode.vue'),'General', 'Generador Codigo')
]