<template>
    <div :class="wrapperClass">
        <label v-if="label">{{label}}</label>
        <i v-if="tooltip != null" v-tooltip.top="{value:tooltip, class: 'custom-alert'}" style="margin-left: .4rem;" :class="tooltipIcon"></i>
        <div class="p-inputgroup">
          <Dropdown v-if="mode == 'normal'"
            v-model="localValue" 
            :options="options" 
            :optionLabel="optionLabel" 
            :optionValue="optionValue"
            :showClear="showClear"
            :filter="filter"
            :disabled="disabled"
            @change="onChange"
            @keydown.tab.exact="$emit('tab')"
            @keydown.shift.tab.exact="$emit('shift-tab')"
            @keydown.enter.exact="$emit('enter')"
            :class="{ 'p-invalid': valid === false }" 
            />
          <Button v-if="search" @click="$emit('search')" icon="pi pi-search" class="p-button-primary" />
          <Button v-if="add" @click="$emit('add')" icon="pi pi-plus" class="p-button-warning" />
          <Button v-if="clear" @click="$emit('clear')" icon="pi pi-ban" class="p-button-danger" />
        </div>
        <small class="p-invalid" v-if="valid === false"> 
              {{validation}}
          </small>
    </div>
</template>

<script>
export default {
  emits: ["clear", "add", "search", "tab", "shift-tab", "enter", "change", "input"],
  props: {
    tooltipIcon: {
        type: [String, null],
        default: "pi pi-question-circle"
    },
    tooltip: {
        type: [String, null],
        default: null
    },
    filter: {
        type: Boolean,
        default: false
    },
    search: {
        type: Boolean,
        default: false
    },
    clear: {
        type: Boolean,
        default: false
    },
    add: {
        type: Boolean,
        default: false
    },
    modelValue: {
      type: [String, Number, null],
      required: true,
    },
    mode: {
      type: String,
      default: "normal"
    },
    descriptions: {
      type: Object,
      default: function() {
        return {
          first: '',
          second: ''
        }
      }
    },
    options: {
      type: Array,
      default: () => []
    },
    showClear: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: null
    },
    optionLabel: {
        type: String,
        default: null
    },
    optionValue: {
        type: String,
        default: null
    },
    wrapperClass: {
        type: String,
        default: "field"
    },
    label: {
        type: String,
        default: null
    },
    valid: {
        type: Boolean,
        //Todos son validos antes del submit
        default: true
    },
    validation: {
        type: String,
        default: "Favor de llenar el campo"
    }
  },
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  methods: {
    setValue(event) {
      console.log(event)
    },
    onChange(event){
      this.$emit('update:modelValue', event.value);
      this.$emit('change', event.value);
    }
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style>

</style>