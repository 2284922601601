<template>
  <div class="layout-footer">
    <div class="footer-logo-container">
      <!-- <img
        id="footer-logo"
        :src="
          'layout/images/logo-' + (colorScheme === 'light' ? 'dark' : 'light') + '.png'
        "
        alt="atlantis-layout"
      /> -->
      <span class="app-name">{{app_name}}</span>
    </div>
    <span class="copyright">&#169; {{app_creator}} - {{new Date().getFullYear()}}</span>
  </div>
</template>

<script>
export default {
  props: {
    colorScheme: String,
  },
  data() {
    return {
      app_name: "N/A",
      app_creator: "N/A"
    };
  },
  created() {
    this.app_name = this.$config.app_name;
    this.app_creator = this.$config.app_creator;
  },
};
</script>
