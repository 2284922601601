import axios from 'axios';
import {
    Abstract
} from './Abstract';

import { BranchCertificate } from "./BranchCertificate";
export class Branch extends Abstract {
    constructor(session) {
        super('general/Branch', session);
        this.name = null;
        this.key_name = null;
        this.address = null;
        this.suburb = null;
        this.city = null;
        this.state = null;
        this.tax_number = null;
        this.zip_code = null;
        this.phone = null;
        this.id_active = false;
        this.tax_regime = null;
        this.email=null;
        this.invoice_user = null;
        this.invoice_password = null;
        this.certificate = new BranchCertificate(session);
    }

    async timbres() {
        let response = await axios.get(this.controller + '/timbres',
        {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async certificate(cer) {
        let response = await axios.post(this.controller + '/certificate/db', cer,
        {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }
}