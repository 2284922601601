const sessionMixin = {
    data() {
        return {
            session: {
                company: 0,
                branch: 0,
                branch_name: "",
                usuario: "",
                perfil: 0,
                user: "",
                menuMode: 'sidebar',
                scheme: 'light',
                api_route: null
            }
        }
    },    
    created() {
        this.session.company = this.$storage.getStorageSync("company");
        this.session.branch = this.$storage.getStorageSync("branch");
        this.session.usuario = this.$storage.getStorageSync("usuario");
        this.session.perfil = this.$storage.getStorageSync("perfil");
        this.session.branch_name = this.$storage.getStorageSync("branch_name") ? this.$storage.getStorageSync("branch_name") : "";
        this.session.api_route = this.$storage.getStorageSync("api_route") ? this.$storage.getStorageSync("api_route") : this.$config.api_route;
        this.session.user = this.session.usuario;
        if(this.$storage.getStorageSync("menuMode")) 
            this.session.menuMode = this.$storage.getStorageSync("menuMode");
        if(this.$storage.getStorageSync("scheme")) 
            this.session.scheme = this.$storage.getStorageSync("scheme");
    },
    methods: {
        changeCompany(company) {
            this.$storage.setStorageSync('company', company.company_external_id ?? company.company);
            this.$storage.setStorageSync('branch', company.branch_external_id ?? company.branch);
            this.$storage.setStorageSync('branch_name', company.branch_name);
            if (company.api_route) 
                this.$storage.setStorageSync('api_route', company.api_route);
            else 
                this.$storage.removeStorageSync("api_route");
            window.location.reload();
        },
        changeBranch(branch) {
            this.$storage.setStorageSync('branch', branch.branch_external_id ?? branch.branch);
            this.$storage.setStorageSync('branch_name', branch.branch_name);
            window.location.reload();
        },
        clearSession() {
            this.$storage.removeStorageSync("company");
            this.$storage.removeStorageSync("branch");
            this.$storage.removeStorageSync("usuario");
            this.$storage.removeStorageSync("perfil");
            this.$storage.removeStorageSync("api_route");
        }
    }
}

export default sessionMixin;