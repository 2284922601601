import moment from 'moment';

export const HEADER_TYPES = {
    currency: (value) => Intl.NumberFormat("es-MX", {style: 'currency', currency: 'MXN'}).format(value),
    currency4: (value) =>  "$" + (value).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
    upper: (value) => (value ?? "").toUpperCase(),
    decimal: (value) => (Math.round(parseFloat(value) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    percentage: (value) => parseFloat(value).toFixed(2)+"%",
    "percentage-decimal": (value) => (parseFloat(value)* 100).toFixed(2)+"%",
    date: (value) => moment(value).format("DD/MM/YYYY"),
    year: (value) =>  moment(value).format("YYYY"),
    month: (value) => moment(value).format("MM"),
    day: (value) => moment(value).format("DD"),
    "date-time": (value) => moment(value).format("DD/MM/YYYY HH:mm:ss A"),
    time: (value) => moment(value,'HH:mm:ss').format("HH:mm:ss A"),
    boolean: (value) => value ? 'SI' : 'NO',
    link: (value) => "<a href=''>" + value + "<a>",
    button: (value) => "<Button>" + value + "</Button>"
  }