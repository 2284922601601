import {
    Abstract
} from './Abstract';

export class Company extends Abstract {
    constructor(session) {
        super('general/Company', session);
        this.name = null;
        this.key_name = null;
        this.address = null;
        this.suburb = null;
        this.city = null;
        this.state = null;
        this.tax_number = null;
        this.zip_code = null;
        this.phone = null;
        this.id_active = false;
    }
}