import { BasicRoute } from './BasicRoute';

export default [
    new BasicRoute('/ticket/catalogos/grupo','tmgrupo',() => import('../pages/Ticket/Catalogos/Grupo.vue'),'Ticket Manager', 'Grupo'),
    new BasicRoute('/ticket/catalogos/cliente','tmcustomer',() => import('../pages/Ticket/Catalogos/Cliente.vue'),'Ticket Manager', 'Cliente'),
    new BasicRoute('/ticket/catalogos/proyecto','tmproyecto',() => import('../pages/Ticket/Catalogos/Proyecto.vue'),'Ticket Manager', 'Proyecto'),
    new BasicRoute('/ticket/catalogos/rol','tmrol',() => import('../pages/Ticket/Catalogos/Rol.vue'),'Ticket Manager', 'Rol'),
    new BasicRoute('/ticket/catalogos/tag','tmtickettag',() => import('../pages/Ticket/Catalogos/TicketTag.vue'),'Ticket Manager', 'Tag'),
    new BasicRoute('/ticket/aplicaciones/administrador','tmadministrador',() => import('../pages/Ticket/Aplicaciones/Administrador.vue'),'Ticket Manager', 'Administrador'),
    new BasicRoute('/ticket/aplicaciones/ticket','tmticket',() => import('../pages/Ticket/Aplicaciones/Ticket.vue'),'Ticket Manager', 'Ticket'),
    new BasicRoute('/ticket/aplicaciones/kanban','tmkanban',() => import('../pages/Ticket/Aplicaciones/Kanban.vue'),'Ticket Manager', 'Kanban'),   
    new BasicRoute('/ticket/reportes/seguimiento','tmseguimiento',() => import('../pages/Ticket/Reportes/Seguimiento.vue'),'Ticket Manager', 'Seguimiento'),
    new BasicRoute('/ticket/reportes/opensupports','tmopensupports',() => import('../pages/Ticket/Reportes/OpenSupport.vue'),'Ticket Manager', 'OpenSupports')
]