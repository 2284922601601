import {
    Abstract
} from './Abstract';

export class BranchCertificate extends Abstract {
    constructor(session) {
        super('general/Branch', session);
        this.cer_file = null;
        this.cer_number = null;
        this.tax_number = null;
        this.cer_name = null;
        this.initial_date = null;
        this.final_date = null;
        this.cer_filename = null;
        this.cer = null;
        this.cer_key_file = null;
        this.cer_key_password = null;
        this.cer_key_name = null;
    }
}