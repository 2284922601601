import { BasicRoute } from "../routes/BasicRoute";
import { ParentRoute } from "../routes/ParentRoute";

export default [
    new ParentRoute('/cxp', 'CXP', () => import('../components/templates/ParentDirectory.vue')),
    new BasicRoute('/cxp/catalogos/concepto','dtpconcepto',() => import('../pages/CXP/Catalogos/Concept.vue'),'CXP', 'Concepto'),
    new BasicRoute('/cxp/catalogos/impuesto','impuesto',() => import('../pages/CXP/Catalogos/Tax.vue'),'CXP', 'Impuesto'),
    new BasicRoute('/cxp/catalogos/proveedor','proveedor',() => import('../pages/CXP/Catalogos/Supplier.vue'),'CXP', 'Proveedor'),
    new BasicRoute('/cxp/catalogos/banco','banco',() => import('../pages/CXP/Catalogos/Bank.vue'),'CXP', 'Banco'),
    new BasicRoute('/cxp/catalogos/cuentabancaria','cuentabancaria',() => import('../pages/CXP/Catalogos/BankAccount.vue'),'CXP', 'Cuenta Bancaria'),
    new BasicRoute('/cxp/catalogos/condicionpago','cxp_condicionpago',() => import('../pages/CXP/Catalogos/PaymentCondition.vue'),'CXP', 'Condicion de Pago'),
    new BasicRoute('/cxp/aplicaciones/solicitudpago','solicitudpago',() => import('../pages/CXP/Aplicaciones/PaymentRequest.vue'),'CXP', 'Solicitud de Pago'),
    new BasicRoute('/cxp/aplicaciones/facturaxpagar','facturaxpagar',() => import('../pages/CXP/Aplicaciones/Invoice.vue'),'CXP', 'Factura por Pagar'),
    new BasicRoute('/cxp/aplicaciones/buzonfacturas','buzonfacturas',() => import('../pages/CXP/Aplicaciones/InvoiceBox.vue'),'CXP', 'Buzon de Facturas'),
    new BasicRoute('/cxp/aplicaciones/conciliacionpagofactura','conciliacionpagofactura',() => import('../pages/CXP/Aplicaciones/PaymentRequestInvoicePay.vue'),'CXP', 'Conciliacion Pagos / Facturas')
]