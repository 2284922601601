<template>
  <div :class="layoutContainerClass" @click="onDocumentClick">
    <div class="layout-main">
      <Toast />
      <Loader v-model="loading" />
      <UserAccess @change-branch="changeBranch" @change-company="changeCompany" :session="session" v-model="modalAccess" />
      <AppTopBar
        :items="menu"
        :menuMode="menuMode"
        :colorScheme="colorScheme"
        :menuActive="menuActive"
        :topbarMenuActive="topbarMenuActive"
        :activeInlineProfile="activeInlineProfile"
        @topbar-item-click="onTopbarItemClick"
        @topbar-subitem-click="onTopbarSubItemClick"
        @menubutton-click="onMenuButtonClick"
        @sidebar-mouse-over="onSidebarMouseOver"
        @sidebar-mouse-leave="onSidebarMouseLeave"
        @toggle-menu="onToggleMenu"
        @change-inlinemenu="onChangeActiveInlineMenu"
        @menu-click="onMenuClick"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
      />

      <AppMenu
        :model="menu"
        :menuMode="menuMode"
        :colorScheme="colorScheme"
        :menuActive="menuActive"
        :sidebarActive="sidebarActive"
        :sidebarStatic="sidebarStatic"
        :pinActive="pinActive"
        :activeInlineProfile="activeInlineProfile"
        @sidebar-mouse-over="onSidebarMouseOver"
        @sidebar-mouse-leave="onSidebarMouseLeave"
        @toggle-menu="onToggleMenu"
        @change-inlinemenu="onChangeActiveInlineMenu"
        @menu-click="onMenuClick"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
        @inlinemenu-click="onInLineMenuClick"
      />

      <AppBreadcrumb
        v-if="breadcrumb"
        :menuMode="menuMode"
        v-model:searchActive="searchActive"
        v-model:searchClick="searchClick"
        @menubutton-click="onMenuButtonClick"
        @rightmenu-button-click="onRightMenuButtonClick"
      />

      <div class="layout-main-content">
         <router-view v-slot="{ Component, route }">
            <!-- Transicion general del sistema -->
            <FadeInOut entry="left" exit="right" :duration="350" mode="out-in">
              <component :is="Component" />
            </FadeInOut>
              <div class="card" v-if="route.path == '/'" >
                <div class="grid">
                  <div with="30px" class="field col-6 md:col-6 xl:col-6'">
                    <ViewReportComponent/>
                  </div>
                  <div class="field col-6 md:col-6 xl:col-6'">
                    <ViewReportComponent/>
                  </div>
                </div>
              </div>
          </router-view>
      </div>

      <AppFooter :colorScheme="colorScheme" />
    </div>

    <AppRightMenu
      :rightMenuActive="rightMenuActive"
      @rightmenu-click="onRightMenuClick"
      @rightmenu-active="onChangeRightMenuActive"
    />

    <AppConfig
      v-model:menuMode="menuMode"
      :theme="theme"
      :componentTheme="componentTheme"
      :colorScheme="colorScheme"
      :configActive="configActive"
      :configClick="configClick"
      @config-button-click="onConfigButtonClick"
      @config-click="onConfigClick"
      @change-color-scheme="onChangeColorScheme"
      @change-component-theme="onChangeComponentTheme"
      @change-menu-theme="onChangeMenuTheme"
      @change-menu-mode="onChangeMenuMode"
    />
  </div>
</template>

<script>
import AppTopBar from "../AppTopbar.vue";
import AppMenu from "../AppMenu.vue";
import AppRightMenu from "../AppRightMenu.vue";
import AppFooter from "../AppFooter.vue";
import AppBreadcrumb from "../AppBreadcrumb.vue";
import AppConfig from "../AppConfig.vue";
import EventBus from "../event-bus";
import Session from "../mixins/rootSessionMixin.js";
import Loader from "./general/Loader.vue";
import { db } from '../db'
import UserAccess from "../components/general/UserAccess.vue";
import InfoWidget from "../components/dashboard/InfoWidget.vue";
import ViewReportComponent from "../pages/General/Herramientas/Components/ViewReportComponent.vue"
export default {
  emits: [
    "layout-mode-change",
    "menu-theme",
    "layoutModeChange",
    "sidebar-mouse-over",
    "sidebar-mouse-leave",
    "change-color-scheme",
    "change-component-theme",
    "change-menu-theme",
    "change-inlinemenu",
  ],
  mixins: [Session],
  props: {
    colorScheme: String,
    topbarTheme: String,
    theme: String,
    componentTheme: String,
  },
  data() {
    return {
      modalAccess: {
        visible: false
      },
      loading: false,
      search: false,
      searchClick: false,
      searchActive: false,
      menuMode: "sidebar",
      menuActive: false,
      activeInlineProfile: false,
      inlineMenuClick: false,
      overlayMenuActive: false,
      topbarMenuActive: false,
      topbarItemClick: false,
      sidebarActive: false,
      sidebarStatic: false,
      pinActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      rightMenuClick: false,
      rightMenuActive: false,
      configActive: false,
      configClick: false,
      menu: [
        {
          label: "Inicio",
          icon: "pi pi-fw pi-home",
          to: "/",
        },
      ],
    };
  },
  async created() {
    // if (!this.session.usuario) 
    //   this.$router.push("/Login");

    if (this.$storage.hasKey('scheme')) {
        let scheme = this.$storage.getStorageSync('scheme');
        this.changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
        this.changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);
        // if (this.$storage.getStorageSync("usuario")) {
        //     this.$router.push('/');
        // }
    }

    try {
      let menu = await db.menus.toArray();
      menu.forEach((element) => {
        if (!element.to) delete element.to;
      });
      let menuFinal = [];
      //* Armamos el menu, primero los parent 'null'
      menuFinal = menu.filter((x) => !x.parent);
      menuFinal.forEach((child) => {
        child.items = menu.filter((x) => x.parent == child.id);
        if (child.items.length == 0) {
          delete child.items;
        }
        child.items.forEach((grandchild) => {
          grandchild.items = menu.filter((x) => x.parent == grandchild.id);
          if (grandchild.items.length == 0) {
            delete grandchild.items;
          }
        });
        this.menu.push(child);
      });
    } catch (error) {
      console.log(error);
    } 
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    changeStyleSheetUrl(id, value, from) {
            const element = document.getElementById(id);
            const urlTokens = element.getAttribute('href').split('/');

            if (from === 1) {
                // which function invoked this function - change scheme
                urlTokens[urlTokens.length - 1] = value;
            } else if (from === 2) {
                // which function invoked this function - change color
                urlTokens[urlTokens.length - 2] = value;
            }

            const newURL = urlTokens.join('/');

            this.replaceLink(element, newURL);
      },
      replaceLink(linkElement, href, callback) {
          const id = linkElement.getAttribute('id');
          const cloneLinkElement = linkElement.cloneNode(true);

          cloneLinkElement.setAttribute('href', href);
          cloneLinkElement.setAttribute('id', id + '-clone');

          linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

          cloneLinkElement.addEventListener('load', () => {
              linkElement.remove();
              cloneLinkElement.setAttribute('id', id);

              if (callback) {
                  callback();
              }
          });
      },
    onDocumentClick() {
      if (!this.searchClick && this.searchActive) {
        this.onSearchHide();
      }

      if (!this.topbarItemClick) {
        this.topbarMenuActive = false;
      }

      if (!this.menuClick) {
        if (this.isHorizontal() || this.isSlim()) {
          this.menuActive = false;
          EventBus.emit("reset-active-index");
        }

        if (this.overlayMenuActive || this.staticMenuMobileActive) {
          this.overlayMenuActive = false;
          this.staticMenuMobileActive = false;
        }

        this.hideOverlayMenu();
        this.unblockBodyScroll();
      }

      if (!this.rightMenuClick) {
        this.rightMenuActive = false;
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      this.topbarItemClick = false;
      this.menuClick = false;
      this.configClick = false;
      this.rightMenuClick = false;
      this.searchClick = false;
      this.inlineMenuClick = false;
    },
    onSearchHide() {
      this.searchActive = false;
      this.searchClick = false;
    },
    onSidebarMouseOver() {
      if (this.menuMode === "sidebar" && !this.sidebarStatic) {
        this.sidebarActive = this.isDesktop();
        setTimeout(() => {
          this.pinActive = this.isDesktop();
        }, 200);
      }
    },
    onSidebarMouseLeave() {
      if (this.menuMode === "sidebar" && !this.sidebarStatic) {
        setTimeout(() => {
          this.sidebarActive = false;
          this.pinActive = false;
        }, 250);
      }
    },
    hideOverlayMenu() {
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;

      if (this.isOverlay()) {
        this.overlayMenuActive = !this.overlayMenuActive;
      }

      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }

      event.preventDefault();
    },
    async onTopbarSubItemClick(event, item) {
      event.preventDefault();
      if (item == 'logout') {
        this.clearSession();
        await db.menus.clear();
        console.log('Regresamos desde Admin')
        this.$router.push("/login");
      }else if (item == 'profile') {
        this.$router.push("/profile/" + this.session.user);
      }else if (item == 'acceses') {
        this.modalAccess.visible = true;
      }
    },
    onTopbarItemClick(event) {
      this.topbarItemClick = true;
      this.topbarMenuActive = !this.topbarMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onRightMenuButtonClick() {
      this.rightMenuClick = true;
      this.rightMenuActive = true;
    },
    onRightMenuClick() {
      this.rightMenuClick = true;
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onInLineMenuClick(event){
      if (event == 'profile') {
        this.$router.push("/profile/" + this.session.user);
      } else if (event == 'invoice') {
        this.$router.push("/invoice/options");
      }
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        this.hideOverlayMenu();
        EventBus.emit("reset-active-index");
      }

      if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
        this.menuActive = false;
      }
    },
    onChangeMenuMode(menuMode) {
      this.menuMode = menuMode;
      this.overlayMenuActive = false;
      //GUARDAMOS VARIABLE DE SESION PARA EL CLIENTE
      this.$storage.setStorageSync("menuMode", menuMode);
    },
    onConfigClick() {
      this.configClick = true;
    },
    onConfigButtonClick(event) {
      this.configActive = !this.configActive;
      this.configClick = true;
      event.preventDefault();
    },
    onChangeRightMenuActive(active) {
      this.rightMenuActive = active;
    },
    onChangeMenuTheme(theme) {
      this.$emit("change-menu-theme", theme);
    },
    onChangeComponentTheme(theme) {
      this.$emit("change-component-theme", theme);
    },
    onChangeColorScheme(scheme) {
      this.$storage.setStorageSync("scheme", scheme);
      this.$emit("change-color-scheme", scheme);
    },
    onToggleMenu(event) {
      this.menuClick = true;

      if (this.overlayMenuActive) {
        this.overlayMenuActive = false;
      }

      if (this.sidebarActive) {
        this.sidebarStatic = !this.sidebarStatic;
      }

      event.preventDefault();
    },
    onChangeActiveInlineMenu() {
      this.activeInlineProfile = !this.activeInlineProfile;
    },
    blockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.add("blocked-scroll");
      } else {
        document.body.className += " blocked-scroll";
      }
    },
    unblockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.remove("blocked-scroll");
      } else {
        document.body.className = document.body.className.replace(
          new RegExp("(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)", "gi"),
          " "
        );
      }
    },
    isHorizontal() {
      return this.menuMode === "horizontal";
    },
    isSlim() {
      return this.menuMode === "slim";
    },
    isOverlay() {
      return this.menuMode === "overlay";
    },
    isStatic() {
      return this.menuMode === "static";
    },
    isSidebar() {
      return this.menuMode === "sidebar";
    },
    isDesktop() {
      return window.innerWidth > 991;
    },
    isMobile() {
      return window.innerWidth <= 991;
    },
  },
  computed: {
    breadcrumb() {
      return this.$route.meta.breadcrumb;
    },
    layoutContainerClass() {
      return [
        "layout-wrapper",
        {
          "layout-static": this.menuMode === "static",
          "layout-overlay": this.menuMode === "overlay",
          "layout-overlay-active": this.overlayMenuActive,
          "layout-slim": this.menuMode === "slim",
          "layout-horizontal": this.menuMode === "horizontal",
          "layout-active": this.menuActive,
          "layout-mobile-active": this.staticMenuMobileActive,
          "layout-sidebar": this.menuMode === "sidebar",
          "layout-sidebar-static": this.menuMode === "sidebar" && this.sidebarStatic,
          "layout-static-inactive":
            this.staticMenuDesktopInactive && this.menuMode === "static",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
  },
  components: {
    InfoWidget,
    UserAccess,
    AppTopBar,
    AppMenu,
    AppRightMenu,
    AppFooter,
    AppBreadcrumb,
    AppConfig,
    Loader,
    ViewReportComponent
  },
};
</script>

<style lang="scss">
@import "../App.scss";
</style>
