<template>
  <div id="layout-config">
    <a
      id="layout-config-button"
      class="layout-config-button"
      @click="onConfigButtonClick($event)"
    >
      <i class="pi pi-cog"></i>
    </a>
    <div
      class="layout-config"
      :class="{ 'layout-config-active': configActive }"
      @click="onConfigClick"
    >
      <h5>Menu</h5>
      <div class="field-radiobutton">
        <RadioButton
          name="menuMode"
          value="static"
          v-model="d_menuMode"
          id="mode1"
          @change="changeMenuMode('static')"
        ></RadioButton>
        <label for="mode1">Estatico</label>
      </div>
      <div class="field-radiobutton">
        <RadioButton
          name="menuMode"
          value="overlay"
          v-model="d_menuMode"
          id="mode2"
          @change="changeMenuMode('overlay')"
        ></RadioButton>
        <label for="mode2">Cubierto</label>
      </div>
      <div class="field-radiobutton">
        <RadioButton
          name="menuMode"
          value="slim"
          v-model="d_menuMode"
          id="mode3"
          @change="changeMenuMode('slim')"
        ></RadioButton>
        <label for="mode3">Reducido</label>
      </div>
      <div class="field-radiobutton">
        <RadioButton
          name="menuMode"
          value="horizontal"
          v-model="d_menuMode"
          id="mode4"
          @change="changeMenuMode('horizontal')"
        ></RadioButton>
        <label for="mode4">Horizontal</label>
      </div>
      <div class="field-radiobutton">
        <RadioButton
          name="menuMode"
          value="sidebar"
          v-model="d_menuMode"
          id="mode5"
          @change="changeMenuMode('sidebar')"
        ></RadioButton>
        <label for="mode5">Barra Lateral</label>
      </div>
      <hr />

      <h5>Color</h5>
      <div class="field-radiobutton">
        <RadioButton
          name="colorScheme"
          value="light"
          v-model="d_colorScheme"
          id="theme1"
          @change="changeColorScheme('light')"
        ></RadioButton>
        <label for="theme1">Blanco</label>
      </div>
      <div class="field-radiobutton">
        <RadioButton
          name="colorScheme"
          value="dark"
          v-model="d_colorScheme"
          id="theme2"
          @change="changeColorScheme('dark')"
        ></RadioButton>
        <label for="theme2">Oscuro</label>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppConfig",
  emits: [
    "config-button-click",
    "config-click",
    "change-color-scheme",
    "change-menu-theme",
    "change-component-theme",
    "change-menu-mode",
  ],
  props: {
    configActive: {
      type: Boolean,
      default: null,
    },
    configClick: {
      type: Boolean,
      default: null,
    },
    colorScheme: String,
    theme: String,
    componentTheme: String,
    menuMode: String,
  },
  data() {
    return {
      d_colorScheme: this.colorScheme,
      d_menuMode: this.menuMode,
    };
  },
  computed: {
    rippleActive() {
      return this.$primevue.config.ripple;
    },
  },
  created() {
    if(this.$storage.getStorageSync("menuMode")) 
    {
      this.d_menuMode = this.$storage.getStorageSync("menuMode");
      this.changeMenuMode(this.d_menuMode);
    }
    if(this.$storage.getStorageSync("scheme")) 
      this.d_colorScheme = this.$storage.getStorageSync("scheme");
  },
  methods: {
    changeTheme(theme) {
      this.$emit("change-menu-theme", theme);
    },
    changeComponentTheme(theme) {
      this.$emit("change-component-theme", theme);
    },
    changeColorScheme(scheme) {
      this.$emit("change-color-scheme", scheme);
    },
    onConfigButtonClick(event) {
      this.$emit("config-button-click", event);
      event.preventDefault();
    },
    onConfigClick(event) {
      this.$emit("config-click", event);
    },
    changeMenuMode(mode) {
      this.$emit("change-menu-mode", mode);
    },
    onRippleChange(value) {
      this.$primevue.config.ripple = value;
    },
  },
};
</script>
