import {
    Abstract
} from './Abstract';

import axios from 'axios';

export class SQLQuery extends Abstract {
    constructor(session) {
        super('general/SQLQuery', session);
        this.id_sql_query_type = null;
        this.title = null;
        this.query = null;
        this.chart_type = '';
        this.chart_label_column = null;
        this.chart_data_column = null;
        this.chart_datasets = 1;
        this.chart_datasets_name = [''];
    }


    async allByProfile() {
        let response = await axios.get(this.controller + '/profile', {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async getReport() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Ingrese un id para encontrar el registro"
        let response = await axios.get(this.controller + "/viewer/" + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async setReport() {
        let response = await axios.post(this.controller + "/viewer", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
}