import {
    Abstract
} from './Abstract';

export class SQLQueryType extends Abstract {
    constructor(session) {
        super('general/SQLQueryType', session);
        this.name = null;
        this.key_name = null;
    }
}