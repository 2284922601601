import axios from "axios";

const sessionMixin = {
    data() {
        return {
            session: {
                company: 0,
                branch: 0,
                branch_name: "",
                usuario: "",
                perfil: 0,
                user: "",
                menuMode: 'sidebar',
                scheme: 'light',
                api_route: null
            }
        }
    },
    emits: [ 'mounted-view' ],
    beforeCreate() {
        let name = this.$route.name;
        if (name != 'profile') {
            this.$emit('mounted-view', this.$route);
        }
    },
    created() {
        this.session.company = this.$storage.getStorageSync("company");
        this.session.branch = this.$storage.getStorageSync("branch");
        this.session.usuario = this.$storage.getStorageSync("usuario");
        this.session.perfil = this.$storage.getStorageSync("perfil");
        this.session.branch_name = this.$storage.getStorageSync("branch_name") ? this.$storage.getStorageSync("branch_name") : "";
        this.session.api_route = this.$storage.getStorageSync("api_route") ? this.$storage.getStorageSync("api_route") : this.$config.api_route;
        this.session.user = this.session.usuario;
        if(this.$storage.getStorageSync("menuMode")) 
            this.session.menuMode = this.$storage.getStorageSync("menuMode");
        if(this.$storage.getStorageSync("scheme")) 
            this.session.scheme = this.$storage.getStorageSync("scheme");
        axios.defaults.baseURL = this.session.api_route;
    },
    methods: {
        clearSession() {
            this.$storage.removeStorageSync("company");
            this.$storage.removeStorageSync("branch");
            this.$storage.removeStorageSync("usuario");
            this.$storage.removeStorageSync("perfil");
            this.$storage.removeStorageSync("api_route");
        },
        toastSuccess(_summary, _detail) {
            this.$toast.add({
                severity: 'success',
                summary: _summary ?? "Exito",
                detail: _detail,
                life: 3000,
            });
        }
    }
}

export default sessionMixin;