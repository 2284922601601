<template>
  <div
    :class="['layout-inline-menu', { 'layout-inline-menu-active': activeInlineProfile }]"
    v-if="!isMobile() && (isStatic() || isSlim() || isSidebar())"
  > 
    <UserAccess @change-branch="changeBranch" @change-company="changeCompany" :session="session" v-model="modalAccess" />
    <a class="layout-inline-menu-action" @click="onChangeActiveInlineMenu">
      <img
        :src="profileImage"
        alt="avatar"
        style="width: 44px; height: 44px"
      />
      <span class="layout-inline-menu-text">{{ session.usuario }}</span>
      <i class="layout-inline-menu-icon pi pi-angle-down"></i>
    </a>

    <transition name="layout-inline-menu-action-panel">
      <ul v-show="activeInlineProfile" class="layout-inline-menu-action-panel">
        <li class="layout-inline-menu-action-item">
          <a>
            <span>{{session.branch_name}}</span>
          </a>
        </li>
        <li @click="logout" class="layout-inline-menu-action-item">
          <a>
            <i class="pi pi-power-off pi-fw"></i>
            <span>Salir</span>
          </a>
        </li>
        <li @click="changeAccess" class="layout-inline-menu-action-item">
          <a>
            <i class="pi pi-cog pi-fw"></i>
            <span>Accesos</span>
          </a>
        </li>
        <li @click="go('profile')" class="layout-inline-menu-action-item">
          <a>
            <i class="pi pi-user pi-fw"></i>
            <span>Perfil</span>
          </a>
        </li>
        <li @click="go('invoice')" class="layout-inline-menu-action-item">
          <a>
            <i class="pi pi-money-bill pi-fw"></i>
            <span>Facturacion</span>
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import Session from "./mixins/rootSessionMixin";
import UserAccess from "./components/general/UserAccess.vue";
import { db } from "./db.js";
export default {
  components: {UserAccess},
  name: "AppInlineMenu",
  emits: ["change-inlinemenu"],
  mixins: [Session],
  props: {
    menuMode: String,
    activeInlineProfile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalAccess: {
        visible: false
      },
      profileImage: null
    }
  },
  mounted() {
        this.profileImage = this.$config.api_route + 'security/ERPUser/image/' + this.session.user;
    },
  methods: {
    changeAccess() {
      this.modalAccess.visible = true;
    },
    go(route) {
      this.$emit('go', route);
    },
    onChangeActiveInlineMenu(event) {
      this.$emit("change-inlinemenu", event);
      event.preventDefault();
    },
    isHorizontal() {
      return this.menuMode === "horizontal";
    },
    isSlim() {
      return this.menuMode === "slim";
    },
    isOverlay() {
      return this.menuMode === "overlay";
    },
    isStatic() {
      return this.menuMode === "static";
    },
    isSidebar() {
      return this.menuMode === "sidebar";
    },
    isDesktop() {
      return window.innerWidth > 991;
    },
    isMobile() {
      return window.innerWidth <= 991;
    },
    async logout() {
      //* Limpiamos Indexed DB y local storage
      this.clearSession();
      await db.menus.clear();
      console.log('Regresamos dese AppInlineMenu')
      this.$router.push("/Login");
    },
  },
};
</script>

<style scoped></style>
