<template>
    <div class="card">
        <Toast />
        <Panel :header="username"> 
            <div class="grid">
                 <div class="col-12">
                <Fieldset legend="Avatar">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-6">
                            <div class="center">
                                <Image ref="avatar" imageClass="avatar" :src="avatarImage" alt="Image" width="125" preview />
                            </div>
                        </div>
                        <div class="field col-6">
                            <FileUpload 
                            name="demo[]" 
                            :url="uploadURL" 
                            @upload="onUpload" 
                            :multiple="false" 
                            accept="image/*" 
                            :maxFileSize="1000000">
                                <template #empty>
                                    <p>Arrastra la imagen aqui</p>
                                </template>
                            </FileUpload>
                        </div>
                    </div>
                </Fieldset>
            </div>
                <div class="col-6">
                <Fieldset legend="Informacion General">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-12">
                            <label>Username</label>
                            <InputText :disabled="true" :value="username" type="text" />
                        </div>
                        <div class="field col-6">
                            <label>Nombre</label>
                            <InputText v-model="user.name" type="text" />
                        </div>
                        <div class="field col-6">
                            <label>Apellidos</label>
                            <InputText v-model="user.lastname" type="text" />
                        </div>
                        <div class="field col-12">
                            <label>E-mail</label>
                            <InputText v-model="user.email" type="text" />
                        </div>
                        <div class="field col-12">
                            <Button @click="updateInfo()">Cambiar informacion</Button>
                        </div>
                    </div>
                </Fieldset>
            </div>
            <!-- <div class="col-6">
                 <Fieldset legend="Cambiar Password">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-12">
                            <label>Nueva Password</label>
                            <InputText type="password" v-model="password"/>
                        </div>
                        <div class="field col-12">
                            <label>Confirmar Password</label>
                            <InputText type="password" v-model="newPassword"/>
                        </div>
                         <div class="field col-12">
                            <Button @click="changePassword()">Cambiar password</Button>
                        </div>
                    </div>
                </Fieldset>
            </div> -->
            </div>
           
        </Panel>
    </div>
</template>

<script>
import Session from "../mixins/sessionMixin";
import { ERPUser } from '../models/seguridad/ERPUser';
import { fillObject } from '../utilities/General';

export default {
    mixins: [Session],
    props: {
        username: {
            type: String,
            default: '/images/general/avatar.jpg'
        }
    },
    data() {
        return {
            uploadURL: null,
            avatarImage: null,
            user: new ERPUser(this.session),
            password: null,
            newPassword: null
        }
    },
    methods: {
        onUpload() {
           this.$toast.add({
            severity: "success",
            summary: "Imagen",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
        },
        async updateInfo() {
            try {
                this.user.accesses = [];
                await this.user.updateInfo();
                 this.$toast.add({
                    severity: "success",
                    summary: "Usuario",
                    detail: "Informacion actualizada con exito",
                    life: 3000,
                });
            } catch (error) {
                 this.$toast.add({
                    severity: "error",
                    summary: "Password",
                    detail: error,
                    life: 3000,
                });
            }
        },
        async changePassword() {
            try {
                let user = new ERPUser(this.session);
                user.username = this.username;
                if (this.password != this.newPassword) 
                    throw "Las contraseñas no son las mismas"
                if (this.newPassword.length < 5)
                    throw "Utilice una contraseña mayor de 5 caracteres"

                await user.updatePassword(this.newPassword);
                this.$toast.add({
                    severity: "success",
                    summary: "Password",
                    detail: "Informacion actualizada con exito",
                    life: 3000,
                });
                this.password = null;
                this.newPassword = null;
            } catch (error) {
                this.$toast.add({
                    severity: "error",
                    summary: "Password",
                    detail: error,
                    life: 3000,
                });
            }
        }
    },
    async mounted() {
        this.avatarImage = this.$config.api_route + 'security/ERPUser/image/' + this.session.user;
        this.uploadURL = this.$config.api_route + 'security/ERPUser/image/' + this.session.user;
        let user = new ERPUser(this.session);
        user.username = this.username;
        let myUser = await user.retrieveByUsername();
        this.user = fillObject(this.user, myUser);
        console.log(this.user);
        
    }
    
}
</script>

<style>
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }

    .avatar {
        min-height: 180px;
        min-width: 180px;
        max-height: 180px;
        max-width: 180px;
        border-radius: 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
</style>