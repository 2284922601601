
export class BasicSimpleChart {
    constructor(labels, data, backgroundColor, hoverBackgroundColor) {
        this.labels = labels;
        this.datasets = [
            {
                data: data,
                backgroundColor: backgroundColor,
                hoverBackgroundColor: hoverBackgroundColor
            }
        ];
    }   
}

export class BasicMultipleChart {
    constructor(labels, datasets) {
        this.labels = labels ?? [];
        this.datasets = datasets ?? [];
    }   
}

export class BasicDataset {
    constructor(label, data, backgroundColor, hoverBackgroundColor) {
        this.label = label ?? "";
        this.data = data ?? [];
        this.backgroundColor = backgroundColor ?? [];
        this.hoverBackgroundColor = hoverBackgroundColor ?? [];
    }   
}


