
export const BASIC_MENUS = {
    login: false,
    error: false,
    access: false,
    register: false,
    resetpassword: false,
    completereset: false,
    test: false,
    invoiceoptions: false,
    profile: false
  }