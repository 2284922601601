<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <div class="col-2"></div>
        <div class="col-8">
            <div class="card register">
                <Toast />
                <Panel header="Reinicio de contraseña">
                    <div class="grid formgrid p-fluid">
                        <FormInputText v-model="form.username" :readonly="true" :wrapperClass="'field col-12'" :label="'Usuario'" />
                        <div class="field col-12">
                            <label for="password">Nueva Contraseña</label>
                            <Password strongRegex="^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" 
                            v-model="form.password" toggleMask>
                                <template #header>
                                    <h6>Eliga una contraseña</h6>
                                </template>
                                <template #footer>
                                    <Divider />
                                    <p class="mt-2">Sugerencias</p>
                                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                        <li>Por lo menos una minuscula</li>
                                        <li>Por lo menos una mayuscula</li>
                                        <li>Por lo menos un numero</li>
                                        <li>Por lo menos un caracter especial</li>
                                        <li>Minimo 8 caracteres</li>
                                    </ul>
                                </template>
                            </Password>
                        </div>
                        <FormInputText :validation="'Las contraseñas no son iguales'" :valid="form.repeatedPassword == form.password" :type="'password'" 
                        v-model="form.repeatedPassword" :wrapperClass="'field col-12'" :label="'Repita la contraseña'" />
                        <Button label="Confirmar" @click="resetPassword" />
                    </div>
                </Panel>
            </div>
        </div>
        <div class="col-2"></div>
    </div>
</template>


<script>
import Loader from "../components/general/Loader.vue";
import FormInputText from "../components/general/FormInputText.vue";
import { ERPUser } from '../models/seguridad/ERPUser';
import { ErrorToast } from '../utilities/General';
export default {
    props: {
        id: null
    },
    components: {
        FormInputText, Loader
    },
    computed: {
        isStrong(){
            /*eslint-disable */
            return (/^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(this.form.password))
        }
    },
    data() {
        return {
            loading: false,
            form: {
                username: null,
                name: null,
                lastname: null,
                email: null,
                password: null,
                repeatedPassword: null
            }
        }
    },
    methods: {
        async resetPassword() {
            try {
                if (!this.isStrong)
                    throw "La contraseña proporcionada no es fuerte, intente con una nueva"
                if (this.form.password != this.form.repeatedPassword)
                    throw "Las contraseñas no son las mismas"
                this.loading = true;
                //Si confirma, lo mandamos al login
                await new ERPUser().confirmReset(this.id, {
                    username: this.form.username,
                    password: this.form.repeatedPassword
                });
                console.log('Regresamos desde Complete Reset')
                this.$router.push('/Login')
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        }
    },
    async mounted() {
        this.loading = true;
        try {
            let entity = await new ERPUser().resetData(this.id);
            this.form.username = entity.username;
        } catch (error) {
            this.$toast.add(new ErrorToast(error));
        } finally {
            this.loading = false;
        }
    }
}
</script>

<style>
    .register {
        margin-top: 5rem;
    }

    .p-password-panel	{
        opacity: 1.0;
        background-color: white;
    }

    .p-password-info	{
        opacity: 1.0;
        background-color: white;
    }
</style>