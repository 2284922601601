import { BasicRoute } from "../routes/BasicRoute";
import { ParentRoute } from "../routes/ParentRoute";

export default [
new ParentRoute('/cfdi40', 'CFDI40', () => import('../components/templates/ParentDirectory.vue')),
new BasicRoute('/cfdi40/catalogos/concepto','concepto',() => import('../pages/CFDI40/Catalogos/Concept.vue'),'CFDI40', 'Concepto'),
new BasicRoute('/cfdi40/catalogos/remolque','remolque',() => import('../pages/CFDI40/Catalogos/ConsignmentNoteTrailer.vue'),'CFDI40', 'Remolques'),
new BasicRoute('/cfdi40/catalogos/figura','figura',() => import('../pages/CFDI40/Catalogos/ConsignmentNoteFigure.vue'),'CFDI40', 'Figura Transporte'),
new BasicRoute('/cfdi40/catalogos/configuracionvehicular','configuracionvehicular',() => import('../pages/CFDI40/Catalogos/ConsignmentNoteVehicleControl.vue'),'CFDI40', 'Configuracion Vehicular'),
new BasicRoute('/cfdi40/catalogos/domiciliofiscal','domiciliofiscal',() => import('../pages/CFDI40/Catalogos/FiscalResidence.vue'),'CFDI40', 'Domicilio Fiscal'),
{
    path: '/cfdi40/herramientas/factura/pdf/:id',
    name: 'cfdi40facturapreview',
    props: true,
    component: () => import('../pages/CFDI40/Herramientas/PreviewInvoice.vue'),
    meta: {
        breadcrumb: [{
            parent: 'CFDI40',
            label: 'Factura'
        }],
    },
},
new BasicRoute('/cfdi40/aplicaciones/cartaporte','cartaporte',() => import('../pages/CFDI40/Aplicaciones/ConsignmentNote.vue'),'CFDI40', 'Carta Porte'),
new BasicRoute('/cfdi40/aplicaciones/aplicacionpagos','aplicacionpagos',() => import('../pages/CFDI40/Aplicaciones/CustomerPayment.vue'),'CFDI40', 'Recibo Electronico de Pago'),
new BasicRoute('/cfdi40/aplicaciones/factura','factura',() => import('../pages/CFDI40/Aplicaciones/Invoice.vue'),'CFDI40', 'Factura 4.0'),
new BasicRoute('/cfdi40/aplicaciones/notacredito','notacredito',() => import('../pages/CFDI40/Aplicaciones/CreditNote.vue'),'CFDI40', 'Nota Credito 4.0'),
new BasicRoute('/cfdi40/aplicaciones/administradorfacturas','administradorfacturas',() => import('../pages/CFDI40/Aplicaciones/InvoiceAdministrator.vue'),'CFDI40', 'Administrar Facturas'),
new BasicRoute('/cfdi40/aplicaciones/administradornotascredito','administradornotascredito',() => import('../pages/CFDI40/Aplicaciones/CreditNoteAdministrator.vue'),'CFDI40', 'Administrar Notas de Credito'),
new BasicRoute('/cfdi40/aplicaciones/administrarclientepago','administrarclientepago',() => import('../pages/CFDI40/Aplicaciones/CustomerPaymentAdministrator.vue'),'CFDI40', 'Administrar Recibo Electronico de Pago'),
]

