export default [{
    path: '/developer/documentacion/database',
    name: 'devdatabase',
    component: () => import('../pages/Developer/Documentacion/Database.vue'),
    meta: {
        breadcrumb: [{
            parent: 'Developer',
            label: 'Documentacion'
        }],
    }
}]