<template>
    <div :class="wrapperClass">
        <OverlayPanel ref="filter">
            asdkoaskdoaskdoaksdokasdokdsao
        </OverlayPanel>
        <DataTable
            v-model:expandedRows="expandedRows"
            ref="dt"
            :loading="loading"
            :rowGroupMode="rowGroupMode"
            :groupRowsBy="groupRowsBy"
            :selectionMode="selectionMode"
            :editMode="'cell'"
            :resizableColumns="resizable"
            columnResizeMode="fit"
            :exportFilename="exportFilename"
            :showGridlines="gridlines"
            :value="rows"
            v-model:selection="selected"
            v-model:contextMenuSelection="selectedContextMenu"
            @rowContextmenu="onRowContextMenu"
            :dataKey="dataKey"
            :paginator="paginator"
            :rows="10"
            @filter="getFilter"
            @row-click="select"
            @rowgroup-expand="onRowExpand"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            :currentPageReportTemplate="currentPageReportTemplate"
            responsiveLayout="scroll"
            v-model:filters="filters"
            :filterDisplay="rowsFilter ? 'row' : null"
            :globalFilterFields="globalFilterFields"
            @cell-edit-complete="onCellEditComplete"
            :alignFrozen="'right'"
        >
            <template #header v-if="headerVisible">
                <Toolbar>
                    <template v-if="exportable" #start>
                        <Button @click="exportCSV" icon="pi pi-file" v-tooltip.top="'Descargar CSV'" class="mr-2" />
                        <Button @click="exportXLS" icon="pi pi-file-excel" v-tooltip.top="'Descargar XLS'" class="p-button-success mr-2" />
                        <Button @click="exportPDF" icon="pi pi-file-pdf" v-tooltip.top="'Descargar PDF'" class="p-button-danger mr-2" />
                    </template>
                    <template #end>
                        <MultiSelect v-if="showVisibleColumns" :modelValue="selectedColumns" 
                        :options="columns" 
                        optionLabel="name" 
                        @update:modelValue="onToggle"
                        placeholder="Columnas Visibles" style="width: 15em; margin-right: 2em;">
                        <template #value>
                            Columnas Visibles
                        </template>    
                        </MultiSelect>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                        </span>
                    </template>
                </Toolbar>
            </template>
            <Column v-if="expandable" :expander="expandable" headerStyle="width: 3rem" />
            <Column v-if="selectionModeColumn == 'multiple' || selectionModeColumn == 'single'" :selectionMode="selectionModeColumn" headerStyle="width: 3em"></Column>
            <Column :showFilterMatchModes="false" 
                    :showFilterMenu="false" :showFilterOperator="false" v-for="header in visibleHeaders" :key="header.value" :field="header.value" :header="header.name" :sortable="sorteable" :style="{ fontSize: fontsize}">
                <template v-if="header.editable" #editor="{ data, field }">
                    <!-- <InputText v-model="data[field]" autofocus /> -->
                    <component :is="header.editable.component" v-bind="header.editable.props"  v-model="data[field]"></component>
                </template>
                <template #body="slotProps">
                    <!-- Descarga de archivos -->
                    <a v-if="header.cfdi" :href="`${apiRoute}${documentRoute}/xml/download/${slotProps.data.id}`">
                        <img :style="{
                            'width': '2.5rem', 
                            'height': '2.5rem',  
                        }" :src="`${publicPath}images/icons/file/xml.png`" />
                    </a>
                    <a v-if="header.cfdi" :href="`${apiRoute}${documentRoute}/pdf/download/${slotProps.data.id}`">
                        <img :style="{
                            'width': '2.5rem', 
                            'height': '2.5rem', 
                        }" :src="`${publicPath}images/icons/file/pdf.png`" />
                    </a>
                    <!-- Imagen del usuario-->
                    <img v-if="header.imgUser" :style="{
                        'width': header.imgUser_width, 
                        'height': header.imgUser_width, 
                        'margin-right': '15px',
                        'border-radius': '22px',
                        'border': '2px solid transparent'
                    }" :src="`${apiRoute}security/ERPUser/image/${slotProps.data.created_by}`"  />
                    
                    <img v-if="header.img" :style="{
                        'width': header.img_width,
                        'margin-right': '15px',
                    }" :src="`${publicPath}images/icons/datatable/${slotProps.data[header.img_field]}.${header.img_ext}`">
                    <div v-if="header.Calendar">
                        <FormCalendar v-if="slotProps.data[header.value] == ''" dateFormat="dd/mm/yy" :showIcon="true" v-model="slotProps.data[header.value]"/>
                        <div v-if="slotProps.data[header.value] == '' ? slotProps.data[header.value] = '' : slotProps.data[header.value]=calendarChange(slotProps.data[header.value])"/>
                    </div>
                    <div v-if="header.Dropdown">
                        <FormDropdownComplex :options="DropdownOptions" :optionLabel="optionLabel" :optionValue="optionValue"
                        :labelInOption="'id - interbank_key'" :labelInValue="'id - interbank_key'" v-model="slotProps.data[header.value]" 
                        :showClear="false" v-on:change="slotProps.data[header.value]=dropdownChange(slotProps.data[header.value])" v-if="slotProps.data[header.value] == ''"/>
                    </div>
                    <div v-if="header.component">
                        <component v-if="header.component == 'Tag'" :severity=getStatus(slotProps.data[header.value]) :is="header.component" v-bind="header.componentProperties" 
                        :value="getValue(slotProps.data[header.value], header, slotProps.data)" @click="componentEventHandler('on-click', slotProps.data, header)">
                        </component>
                    </div>
                    <div v-else :style="{ fontSize: fontsize }">
                        <!-- Custom, aqui solo aplica cuando se quiere renderizar un componente externo -->
                        <Chip @click="$emit('chip-clicked-' + header.value, slotProps.data)" v-if="header.component == 'chip'" :label="getValue(slotProps.data[header.value], header, slotProps.data)" :icon="header.component_options.icon" class="custom-chip" />
                        <span v-else v-html="getValue(slotProps.data[header.value], header, slotProps.data)"></span>
                    </div>
                </template>
                <template v-if="rowsFilter && header.filter" #filter="{filterModel, filterCallback}">
                    <InputText v-model="filterModel.value" v-if="header.type == 'date-time' || header.type == 'date' || header.type == 'time'" :type="header.type == 'date-time' ? 'datetime' : header.type" @input="filterCallback()" />
                    <!-- <InputText v-else-if="header.filter_options" v-model="filterModel.value"  @keydown.enter="filterCallback()" /> -->
                    <Dropdown v-else-if="header.filter_options" 
                    v-model="filterModel.value" pi
                    @change="filterCallback()"
                    :options="[...new Set(rows.map(x => x[header.value]))]" />
                    <InputText v-else v-model="filterModel.value"  @keydown.enter="filterCallback()" />
                </template>
                <template v-if="footerVisible" #footer>
                    {{ footers[header.name] }}
                </template>
            </Column>
            <Column :style="{ width: getActionRowWidth() + 'px' }" v-if="rowaction" style="align-items: end" :header="rowname">
                <template #body="slotProps">
                    <Button v-if="rowview" icon="pi pi-eye" class="p-button-rounded p-button-warning p-mr-2" @click="view(slotProps.data)" />
                    <Button v-if="rowviewDocument" icon="pi pi-eye" class="p-button-rounded p-button-warning p-mr-2" @click="viewDocument(slotProps.data)" />
                    <Button v-if="rowedit" icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="edit(slotProps.data)" />
                    <Button v-if="rowdelete" icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" @click="deleted(slotProps.data)" />
                    <Button v-if="authorize" icon="pi pi-check-square" class="p-button-rounded p-button-success p-mr-2" @click="auth(slotProps.data)" />
                    <Button v-if="supply" icon="pi pi-shopping-cart" class="p-button-rounded p-button-warning p-mr-2" @click="supp(slotProps.data)" />
                    <Button v-if="deleteSign" icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2" @click="sign(slotProps.data)" />
                    <Button v-if="rowemail" icon="pi pi-envelope" class="p-button-rounded p-button-info p-mr-2" @click="clickemail(slotProps.data)" />
                    <Button v-if="rowlog" icon="pi pi-exclamation-circle" class="p-button-rounded p-button-warning p-mr-2" @click="log(slotProps.data)" v-tooltip.top="''" />
                    <slot name="custom-row-button" :slot-scope="slotProps.data"></slot>
                    <FileIcon v-for="file in files" @click="$emit(file, slotProps.data)" :key="file" :extension="file" />
                </template>
            </Column>
            <template #expansion="expansionProps">
                <BasicDatatable selectionMode="single" :dataKey="expansionKey" :rows="expansionProps.data[rowsExpandable]" 
                :paginator="false" @selected="selectDetail" :headers="headersExpandable" :rowsFilter="false" responsiveLayout="scroll" 
                :headerVisible="false" :footerVisible="false"></BasicDatatable>
            </template>
            <template #groupheader="slotProps" v-if="rowGroupMode">
                <h5 :style="{ fontSize: fontsizegroup, textAlign: 'center' }">{{ slotProps.data[groupRowsBy] }}</h5>
                <div :style="{ fontSize: fontsizegroup, textAlign: 'center' }">{{ getSubheaderGrouped(slotProps.data[groupRowsBy]) }}</div>
            </template>
        </DataTable>
        <ContextMenu v-if="contextMenu" :model="menuModelInternal" ref="cm" />
    </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
var math = require('mathjs');
import { HEADER_TYPES } from '../../utilities/HEADER_TYPES';
import { FOOTER_FUNCTIONS } from '../../utilities/FOOTER_FUNCTIONS';
import { COLUMN_FORMULA } from '../../utilities/COLUMN_FORMULA';
import { exportXLS } from '../../utilities/EXCEL_UTILITIES';
import  FormDropdownComplex  from '../../components/general/FormDropdownComplex.vue';
import FormCalendar from './FormCalendar.vue';
import { CustomerBankAccount } from '../../models/comercial/CustomerBankAccount';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import FileIcon from '../general/FileIcon.vue';
import { FilterService } from 'primevue/api';
import moment from "moment";

FilterService.register("DATE", (value,filter) => {
    if (filter == null) {
        return true
    }
    else if (filter == ""){
        return true
    }
    else{
        value = value.includes("T") ? value.substring(0, value.length - 9) : value;
        return value == filter;
    }
});

export default {
    name: "BasicDatatable",
    emits: ['selected', 'filtered-footer', 'selected-change', 'selected-detail', 'deleted', 'edited', 'view','viewDocument', 'authorized', 'supplied','signed', 'logged', 'emailed'],
    expose: ['set', 'exportXLS', 'exportPDF', 'exportCSV'],
    components: { FileIcon, FormDropdownComplex, FormCalendar},
    props: {
        wrapperClass: {
        type: String,
        default: "field"
        },
        optionValue: {
            type: String,
            default: null
        },
        optionLabel: {
            type: String,
            default: null
        },
        DropdownOptions: {
            type: Array,
            default: () => [],
        },
        aboutInfo:{
            type: Function,
        },
        loading: {
            type: Boolean,
            default: false
        },
        rowsFilter: {
            type: Boolean,
            default: true
        },
        showVisibleColumns: { 
            type: Boolean,
            default: true
        },
        expansionKey: {
            type: String,
            default: "id",
        },
        footerVisible: {
            type: Boolean,
            default: true,
        },
        exportable: {
            type: Boolean,
            default: true,
        },
        files: {
            type: Array,
            default: () => [],
        },
        name: {
            type: String,
            default: 'Reporte',
        },
        exportFilename: {
            type: String,
            default: 'Descarga',
        },
        currentPageReportTemplate: {
            type: String,
            default: 'Mostrando {first} a {last} de {totalRecords} resultados',
        },
        rowsExpandable: {
            type: String,
            default: '',
        },
        headerVisible: {
            type: Boolean,
            default: true,
        },
        headersExpandable: {
            type: Array,
            default: () => [],
        },
        fontsize: {
            type: String,
            default: 'small',
        },
        fontsizegroup: {
            type: String,
            default: 'small',
        },
        rowGroupMode: {
            type: String,
            default: null,
        },
        rowSubgroupMode: {
            type: String,
            default: null,
        },
        groupRowsBy: {
            type: String,
            default: null,
        },
        subgroupRowsBy: {
            type: String,
            default: null,
        },
        dataKey: {
            type: String,
            default: 'id',
        },
        headers: {
            type: Array,
            default: () => [],
        },
        rows: {
            type: Array,
            default: () => [],
        },
        actionRowWidth: {
            type: [Number, null],
            default: null,
        },
        expandable: {
            type: Boolean,
            default: false,
        },
        sortable: {
            type: Boolean,
            default: true,
        },
        delete: {
            type: Boolean,
            default: false,
        },
        gridlines: {
            type: Boolean,
            default: true,
        },
        resizable: {
            type: Boolean,
            default: false,
        },
        rowaction: {
            type: Boolean,
            default: false,
        },
        rowname: {
            type: String,
            default: null,
        },
        rowdelete: {
            type: Boolean,
            default: false,
        },
        rowedit: {
            type: Boolean,
            default: false,
        },
        rowview: {
            type: Boolean,
            default: false,
        },
        rowviewDocument: {
            type: Boolean,
            default: false,
        },
        supply: {
            type: Boolean,
            default: false,
        },
        deleteSign: {
            type: Boolean,
            default: false,
        },
        rowlog: {
            type: Boolean,
            default: false,
        },
        rowemail:{
            type: Boolean,
            default: false,
        },
        authorize: {
            type: Boolean,
            default: false,
        },
        selectionMode: {
            type: String,
            default: null,
        },
        selectionModeColumn: {
            type: String,
            default: 'none',
        },
        modelValue: {
            type: Array,
        },
        contextMenu: {
            type: Boolean,
            default: false,
        },
        sorteable: {
            type: Boolean,
            default: false,
        },
        menuModel: {
            type: Array,
            default: () => [],
        },
        paginator: {
            type: Boolean,
            default: true
        },
        documentRoute: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            apiRoute: this.$config.api_route,
            filteredValue: [],
            filters: {},
            selected: this.modelValue,
            selectedColumns: [],
            columns: [],
            selectedContextMenu: null,
            expandedRows: [],
            globalFilterFields: [],
            footers: {},
            groupedValue: null,
            CustomerBank: [],
            calendarDate: null,
        };
    },
    watch: {
        filteredValue(newValue) {
            this.setFooters(newValue);
        }, 
        ['rows.length'](newValue) { 
            if (newValue == 0) {
                this.selected = [];
                this.filteredValue = [];
            }else 
                this.setFooters(this.rows);
        },
        modelValue(newValue) {
            this.selected = newValue;
        },
        selected(newValue) {
            this.$emit('selected-change', newValue);
        },
        async headers(newValue) {
            this.columns = newValue.filter((x) => x.value != this.groupRowsBy);
            this.selectedColumns = newValue.filter((x) => x.value != this.groupRowsBy);

            this.globalFilterFields = newValue.map(x => x.value);
            
            //Esto se cambia por header
            this.globalFilterFields.forEach(filter => {
                let header = newValue.find(x => x.value == filter);
                this.filters[filter] = {
                    value: null,
                    matchMode: header.type == "date" ? FilterMatchMode.DATE_IS : FilterMatchMode.CONTAINS
                }
            });
        }
    },
    methods: {
        dropdownChange(slotProps){
            let id_reg;
            if(this.optionLabel=="interbank_key"){
                let table = this.DropdownOptions.find(x => x.id == slotProps)
                let id_reg=slotProps+" - "+table.interbank_key
                return id_reg
            }
            return id_reg
        },
        calendarChange(slotProps){
            let dateFormat = slotProps;
            if(slotProps != this.calendarDate){
                let date = moment(slotProps).format();
                let dateFormat = moment(date).format('YYYY-MM-DD');
                this.calendarDate = dateFormat
                return dateFormat
            }
            return dateFormat
        },
        async refresh() {
            this.CustomerBank = await new CustomerBankAccount(this.session).all();
        },
        getStatus(status){
            switch (status) {
                case 'Registrado':
                    return 'null';

                case 'Autorizado':
                    return 'success';

                case 'Surtido':
                    return 'warning';

                case 'Requerido':
                    return 'warning';
                
                case 'Afectado':
                    return 'success';

                case 'negotiation':
                    return 'warning';
                
                case 'Cancelado':
                    return 'danger';
                
                case 'Pagado':
                    return 'success';

                case 'Pagado parcial':
                    return 'warning';

                case 'renewal':
                    return null;

                case 'Sin Timbrar':
                    return 'null';
                
                case 'Timbrado':
                    return 'success';

                case 'En Proceso de Cancelacion':
                    return 'warning';
                
                case 'Sin Pedido' : 
                    return 'warning';

                case 'Con Pedido' :
                    return 'success';
            }
        },
        onCellEditComplete(event) {
            let { data, newValue, field } = event;
            //* Checar si tenemos otro model en el header
            let editable = this.headers.find(x => x.value == field).editable;
            data[editable.model ?? field] = newValue;
            this.$emit('cell-editing-complete', {
                field: editable.model ?? field,
                data: data,
                value: newValue
            });
        },
        componentEventHandler(internalEvent, data, header) {
            this.$emit(internalEvent + "-component", {
                data: data,
                header: header
            });
        },
        setFooters(rows) {
            let res_fun = null;
            this.footers = {};
            this.visibleHeaders.forEach(header => {
                let my_rows = rows ? rows.map((x) => x) : [];
                if (header.formula) {
                //* Se necesita evaluar primero
                    my_rows.forEach(my_row => {
                        my_row[header.value] = COLUMN_FORMULA[header.formula] ? COLUMN_FORMULA[header.formula](header.expression, my_row, header) : my_row[header.value];
                    });
                    res_fun = FOOTER_FUNCTIONS[header.function] ? FOOTER_FUNCTIONS[header.function](my_rows, header.value) : null;
                }
                else 
                    res_fun = FOOTER_FUNCTIONS[header.function] ? FOOTER_FUNCTIONS[header.function](my_rows, header.value) : null;
                let result = res_fun == null ? null : this.getValue(res_fun, header, null);
                this.footers[header.name] = result;
            });
            this.$emit("filtered-footer", this.footers);
        },
        getFilter(event) {
            this.filteredValue = event.filteredValue;
        },
        onToggle(value) {
            this.selectedColumns = this.columns.filter(col => value.includes(col));
        },
        exportXLS() {
            exportXLS(this.filteredValue, this.visibleHeaders, this.exportFilename);
        },
        exportPDF() {
            const exportColumns = this.visibleHeaders.map((header) => ({ title: header.name, dataKey: header.value }));
            
            const doc = new jsPDF();
            doc.autoTable({
                columns: exportColumns,
                body: this.filteredValue,
                margin: { top: 35 },
                didDrawPage: () => {
                    doc.text(this.name, 20, 30);
                },
            });
            doc.save(this.exportFilename + '.pdf');
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        selectDetail(payload) {
            this.$emit('selected-detail', payload.data);
        },
        onRowExpand() {
            alert('Expanded');
        },
        getSubheaderGrouped(val) {
            if (this.rowSubgroupMode == 'SUM') {
                let sum = this.filteredValue.reduce((a, b) => {
                    if (b[this.groupRowsBy] == val) {
                        return math
                            .chain(a)
                            .add(b[this.subgroupRowsBy] ?? 0.0)
                            .done();
                    } else {
                        return a;
                    }
                }, 0);

                return Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                }).format(sum);
            } else return val;
        },
        onRowContextMenu(event) {
            if (this.contextMenu) this.$refs.cm.show(event.originalEvent);
        },
        getActionRowWidth() {
            if (this.actionRowWidth) 
                return this.actionRowWidth;
            else
                return this.rowemail && this.rowlog && this.deleteSign && this.supply && this.authorize && this.rowdelete && this.rowedit && this.rowview || (this.deleteSign && this.rowlog && this.rowemail) ? 150 : (this.rowdelete && this.rowedit) || (this.rowdelete && this.rowview) || (this.authorize && this.supply) || (this.authorize && this.rowedit) ? 100 : 50;
        },
        auth(payload){
            this.$emit('authorized', payload);
        },
        supp(payload){
            this.$emit('supplied', payload);
        },
        sign(payload){
            this.$emit('signed', payload);
        },
        log(payload){
            this.$emit('logged', payload);
        },
        clickemail(payload){
            this.$emit('emailed', payload);
        },
        set(data) {
            alert(data);
        },
        deleted(payload) {
            this.$emit('deleted', payload);
        },
        edit(payload) {
            this.$emit('edited', payload);
        },
        view(payload) {
            this.$emit('view', payload);
        },
        viewDocument(payload) {
            this.$emit('viewDocument', payload);
        },
        select(payload) {
            this.$emit('selected', payload.data);
        },
        getValue(value, header, row) {
            if (header.formula && row) value = COLUMN_FORMULA[header.formula] ? COLUMN_FORMULA[header.formula](header.expression, row, header) : value;
            return HEADER_TYPES[header.type] ? HEADER_TYPES[header.type](value) : value;
        },
    },
    computed: {
        visibleHeaders() {
            return this.headers
                    .filter((x) => x.value != this.groupRowsBy)
                    .filter((x) => this.selectedColumns
                                        .find(col => col.name == x.name));
        },
        menuModelInternal() {
            return this.menuModel.map((x) => {
                return {
                    label: x.label,
                    icon: x.icon,
                    command: () => this.$emit(x.action, this.selectedContextMenu),
                };
            });
        }
    },
    async mounted() { 
        await this.refresh();
        this.columns = this.headers.filter((x) => x.value != this.groupRowsBy);
        this.selectedColumns = this.headers.filter((x) => x.value != this.groupRowsBy);

        this.globalFilterFields = this.headers.map(x => x.value);
        /* 
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                representative: { value: null, matchMode: FilterMatchMode.IN },
                status: { value: null, matchMode: FilterMatchMode.EQUALS },
                verified: { value: null, matchMode: FilterMatchMode.EQUALS }*/
        this.globalFilterFields.forEach(filter => {
            let header = this.headers.find(x => x.value == filter);
            this.filters[filter] = {
                value: null,
                matchMode: header.type == "date" ? "DATE" : FilterMatchMode.CONTAINS
            }
        });
    },
    created() {
        this.filters = {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
    },
};
</script>

<style lang="scss" scoped>
.p-chip.custom-chip {
    background: var(--primary-color);
    color: var(red);
    cursor: pointer;
}
</style>
