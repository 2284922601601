<template>
    <Dialog v-model:visible="localValue.visible" :style="{ width: '450px' }" :modal="true" :closable="true" header="Accesos de usuario">
        <div class="grid formgrid p-fluid">
            <FormDropdown :wrapperClass="'col-12 field'" v-model="company" label="Empresa" :options="companies" :optionLabel="'company_name'" :optionValue="'id_company'" />
            <FormDropdown :wrapperClass="'col-12 field'" v-model="branch" label="Sucursal" :options="branches" :optionLabel="'branch_name'" :optionValue="'id_branch'" />
        </div>
        <template #footer>
            <Button label="Cambiar accesos" icon="pi pi-check" class="p-button-text" @click="changeAccess" />
        </template>
    </Dialog>
</template>

<script>
import { ERPUser } from '../../models/seguridad/ERPUser';
import FormDropdown from './FormDropdown.vue';
export default {
    components: { FormDropdown },
    props: {
        modelValue: {
            required: true,
        },
        session: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            localValue: this.modelValue,
            acceses: [],
            companies: [],
            company: null,
            branch: null,
        };
    },
    methods: {
        changeAccess() {
            console.log(this.branch);
            console.log(this.session.branch);
            if (this.company && this.branch) {
                if (this.branch == this.session.branch) {
                    //Igual, no cambia nada
                    this.localValue.visible = false;
                } else {
                    if (this.company == this.session.company) {
                        //Super basico, solo cambiamos sucursal
                        this.$emit('change-branch', {
                            branch: this.branch,
                            branch_name: this.acceses.find((x) => x.id_branch == this.branch).branch_name,
                            branch_external_id: this.acceses.find((x) => x.id_branch == this.branch).branch_external_id,
                        });
                    } else {
                        //Cambio general
                        this.$emit('change-company', {
                            company: this.company,
                            api_route: this.acceses.find((x) => x.id_branch == this.branch).api_route,
                            branch_name: this.acceses.find((x) => x.id_branch == this.branch).branch_name,
                            company_external_id: this.acceses.find((x) => x.id_branch == this.branch).company_external_id,
                            branch_external_id: this.acceses.find((x) => x.id_branch == this.branch).branch_external_id,
                            branch: this.branch,
                        });
                    }
                }
            }
        },
    },
    async updated() {
        if (this.localValue.visible) {
            let user = new ERPUser(this.session);
            user.username = this.session.user;
            this.acceses = await user.access();
            console.log(this.acceses);
            this.acceses.forEach((x) => {
                if (!this.companies.find((y) => y.id_company == x.id_company)) {
                    this.companies.push(x);
                }
            });
            this.company = this.session.company;
        }
    },
    computed: {
        branches() {
            return this.acceses.filter((x) => {
                if (x.id_company == this.company) {
                    this.localValue.visible;
                    return x;
                }
            });
        },
    },

    watch: {
        company() {
            this.branch = null;
        },
        localValue(newValue) {
            this.$emit('input', newValue);
        },
        modelValue(newValue) {
            this.localValue = newValue;
        },
    },
};
</script>

<style></style>
