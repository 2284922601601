import { BasicRoute } from "../routes/BasicRoute";
import { ParentRoute } from "../routes/ParentRoute";

export default [
    new ParentRoute('/comercial', 'Comercial', () => import('../components/templates/ParentDirectory.vue')),
    new BasicRoute('/comercial/catalogos/seriedocumento','serie',() => import('../pages/Comercial/Catalogos/Serie.vue'),'Comercial', 'Serie Documento'),
    new BasicRoute('/comercial/catalogos/unidadmedida','unidadmedida',() => import('../pages/Comercial/Catalogos/MeasureUnit.vue'),'Comercial', 'Unidad Medida'),
    new BasicRoute('/comercial/catalogos/clasificacioncliente','clasificacioncliente',() => import('../pages/Comercial/Catalogos/CustomerClassification.vue'),'Comercial', 'Tipo Cliente'),
    new BasicRoute('/comercial/catalogos/listaprecio','listaprecio',() => import('../pages/Comercial/Catalogos/PriceList.vue'),'Comercial', 'Lista Precio'),
    new BasicRoute('/comercial/catalogos/cliente','cliente',() => import('../pages/Comercial/Catalogos/Customer.vue'),'Comercial', 'Cliente'),
    new BasicRoute('/comercial/catalogos/grupo','grupo',() => import('../pages/Comercial/Catalogos/ProductGroup.vue'),'Comercial', 'Grupo'),
    new BasicRoute('/comercial/catalogos/condicionpago','cxc_condicionpago',() => import('../pages/Comercial/Catalogos/PaymentCondition.vue'),'Comercial', 'Condición de Pago'),
    new BasicRoute('/comercial/catalogos/familia','familia',() => import('../pages/Comercial/Catalogos/ProductFamily.vue'),'Comercial', 'Familia'),
    new BasicRoute('/comercial/catalogos/vendedor','vendedor',() => import('../pages/Comercial/Catalogos/SalesMan.vue'),'Comercial', 'Vendedor'),
    new BasicRoute('/comercial/aplicaciones/pedido','pedido',() => import('../pages/Comercial/Aplicaciones/Order.vue'),'Comercial', 'Pedido'),
    new BasicRoute('/comercial/aplicaciones/administrarpedido','administrarpedido',() => import('../pages/Comercial/Aplicaciones/OrderManager.vue'),'Comercial', 'Administrar Pedido'),
    new BasicRoute('/comercial/aplicaciones/depositos','depositos',() => import('../pages/Comercial/Catalogos/Deposit.vue'),'Comercial', 'Depositos'),
]


