<template>
  <div class="layout-breadcrumb-container">
    <div class="layout-breadcrumb-left-items">
      <a
        href="#"
        class="menu-button"
        @click="onMenuButtonClick($event)"
        v-if="isStatic()"
      >
        <i class="pi pi-bars"></i>
      </a>
      <Breadcrumb :model="items" class="layout-breadcrumb"></Breadcrumb>
    </div>
    <div class="layout-breadcrumb-right-items">
      <a tabindex="0" class="search-icon" @click="breadcrumbClick">
        <i class="pi pi-search"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuMode: String,
    searchActive: {
      type: Boolean,
      default: false,
    },
    searchClick: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "menubutton-click",
    "rightmenu-button-click",
    "update:searchActive",
    "update:searchClick",
  ],
  data() {
    return {
      items: [],
      search: "",
    };
  },
  watch: {
    $route() {
      this.watchRouter();
    },
  },
  created() {
    this.watchRouter();
  },
  methods: {
    watchRouter() {
      if (this.$router.currentRoute.value.meta.breadcrumb) {
        this.items = [];
        const bc = this.$router.currentRoute.value.meta.breadcrumb[0];
        this.items.push({ icon: 'pi pi-home', to: '/' });
        //? NO SE CUAL SE QUEDA
        // for (let pro in bc) {
        //   this.items.push({ label: bc[pro] });
        // }
        let to = bc.parent.to ?? "";
        this.items.push({ label: bc.parent.title, to: to.replace(/\s/g, '') });
        this.items.push({ label: bc.label });
      }
    },
    onMenuButtonClick(event) {
      this.$emit("menubutton-click", event);
    },
    onRightMenuButtonClick() {
      this.$emit("rightmenu-button-click");
    },
    inputClick() {
      this.$emit("update:searchClick", true);
    },
    breadcrumbClick() {
      this.$emit("update:searchActive", true);
      this.$emit("update:searchClick", true);
    },
    isStatic() {
      return this.menuMode === "static";
    },
  },
};
</script>

<style scoped>
::v-deep(.p-breadcrumb > ul > li.p-breadcrumb-chevron:first-of-type) {
  display: none;
}
</style>
