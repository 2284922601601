<template>
    <div class="card">
        <Toast />
        <Alert v-if="error" :message="error" :visible="true"></Alert>
        <Panel header="Informacion de Facturacion"> 
            <div class="grid">
                <div class="col-12">
                <Fieldset legend="Informacion General">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-12">
                            <label>Empresa</label>
                            <InputText v-model="entity.company" type="text" readonly />
                        </div>
                        <div class="field col-6">
                            <label>Sucursal</label>
                            <InputText v-model="entity.branch" type="text" readonly />
                        </div>
                        <div class="field col-6">
                            <label>RFC</label>
                            <InputText v-model="entity.branch" type="text" readonly />
                        </div>
                        <div class="field col-3"> 
                            <label>Timbres Disponibles</label>
                            <div class="p-inputgroup">
                                <Button label="Verificar Timbres" @click="verificarTimbre()"/>
                                <InputText readonly v-model="entity.saldo"/>
                            </div>
                        </div>
                        <div class="field col-3"> 
                            <label>Timbres Utilizados</label>
                            <InputText readonly v-model="entity.utilizados"/>
                        </div>
                        <div class="field col-3"> 
                            <label>Timbres Asignados</label>
                            <InputText readonly v-model="entity.asignados"/>
                        </div>
                    </div>
                </Fieldset>
            </div>
            </div>
        </Panel>
    </div>
</template>

<script>
import Session from "../mixins/sessionMixin";
import { Branch } from "../models/general/Branch";
import Alert from "../components/general/Alert.vue";
export default {
    components: {Alert},
    mixins: [Session],
    data() {
        return {
            error: null,
            my_branch: new Branch(this.session),
            entity: {}
        }
    },
    methods: {
        async verificarTimbre() {
            try {
                this.my_branch = new Branch(this.session);
                this.entity = await this.my_branch.timbres();
                this.entity.saldo = this.entity.timbrado.data.saldoTimbres;
                this.entity.utilizados = this.entity.timbrado.data.timbresUtilizados;
                this.entity.asignados = this.entity.timbrado.data.timbresAsignados;
            } catch (error) {
                this.error = error.response.data;
            }
        }
    },
    async mounted() {
        await this.verificarTimbre();
    }
}
</script>

<style>
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }

    .avatar {
        min-height: 180px;
        min-width: 180px;
        max-height: 180px;
        max-width: 180px;
        border-radius: 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
</style>