var math = require("mathjs");

const searchFunction = (expression, scope, header) => expression == "find" ? 
    (header.data.find(x => x[header.data_key] == scope[header.data_search_key]) ?? {}) : {};

const concatFunction = (expression, scope) => {
  let props = expression.split('+').map(x => {
    return !(x.includes("{") && x.includes("}")) ? x.replace(" ", "") : x.substring(1).slice(0, -1);
  });
  if (props.length > 0) {
    let result = "";
    props.forEach(prop => {
        result += prop.includes('{') && prop.includes('}') ? prop.substring(1).slice(0, -1) : (scope[prop] ?? "");
    });
    return result;
  } else 
  return "ERROR";
}

export const COLUMN_FORMULA = {
    evaluate: (expression, scope) => math.evaluate(expression, scope),
    today_difference: (expression, scope) => Math.round((new Date(scope[expression]).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)),
    boolean_express: (expression, scope, header) =>  eval("scope." + expression) ? header.true_res : header.false_res,
    color: (expression, scope, header) => "<span style='color: "+ eval("scope." + expression) +";'>" + scope[header.value] +"</span>",
    search: (expression, scope, header) => searchFunction(expression, scope, header)[header.data_value] ?? "N/D",
    concat: (expression, scope) => concatFunction(expression, scope),
    search_concat: (expression, scope, header) => concatFunction(expression.concat, searchFunction(expression.search, scope, header)),
  }