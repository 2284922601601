<template>
    <div class="card widget-overview-box">
        <span class="overview-title">
            {{title}}
        </span>
        <div class="flex justify-content-between">
            <div class="overview-detail flex justify-content-between">
                <!-- <div class="overview-badge flex justify-content-center align-items-center">
            <i class="pi pi-arrow-down"></i>
            <span>$5,025,521.00</span>
        </div> -->
                <div class="overview-text">
                    {{ text }}
                </div>
            </div>
        </div>
        <img v-if="image" style="width: 4rem;" :src="image">
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        image: {
            type: String,
            default: null
        }
    }
}
</script>

<style></style>