<template>
    <div :class="wrapperClass">
        <label v-if="label">{{label}}</label>
        <i v-if="tooltip != null" v-tooltip.top="{value:tooltip, class: 'custom-alert'}" style="margin-left: .4rem;" class="pi pi-question-circle"></i>
        <Textarea v-if="textArea" 
          :rows="rows" 
          :autoResize="true" 
          :class="{ 'p-invalid': valid === false }" 
          v-model="localValue"
          @keydown.tab="$emit('tab')"
          @keydown.shift.tab.exact="$emit('shift-tab')"
          @keydown.enter.exact="$emit('enter')"
          @input="$emit('update:modelValue', $event.target.value)"
        />
        <div v-else class="p-inputgroup">
            <InputMask v-if="type == 'mask'" 
            :mask="mask" 
            @change="$emit('update:modelValue', $event.target.value)"
            :class="{ 'p-invalid': valid === false }"
            v-model="localValue"  
            :placeholder="placeholder" 
            />
            <InputText v-else
            ref="control"
            :name="name"
            :autocomplete="autocomplete"
            :placeholder="placeholder" 
            :type="type"
            :disabled="disabled"
            v-model="localValue" 
            :readonly="readonly"
            :class="{ 'p-invalid': valid === false }"
            @input="$emit('update:modelValue', $event.target.value)"
            @keydown.shift.tab.exact="$emit('shift-tab')"
            @keydown.tab="$emit('tab')"
            @keydown.enter.exact="$emit('enter')" 
            :focuScanner=focuScanner />
            <Button v-if="search" @click="$emit('search')" icon="pi pi-search" class="p-button-primary" />
            <Button v-if="add" @click="$emit('add')" icon="pi pi-plus" class="p-button-warning" />
            <Button v-if="view" @click="$emit('view')" icon="pi pi-eye" class="p-button-info" />
            <Button v-if="clear" @click="$emit('clear')" icon="pi pi-ban" class="p-button-danger" />
            <Button v-if="barcode" @click="$emit('barcode')" icon="pi pi-clone" class="p-button-primary" />
        </div>
        
        <small class="p-invalid" v-if="valid === false"> 
            {{validation}}
        </small>
    </div>
</template>

<script>
export default {
  emits: ['update:modelValue', 'input'],
  props: {
    name: {
      type: [String, null],
      default: null
    },
    autocomplete: {
      type: [String, null],
      default: "off"
    },
    tooltip: {
        type: [String, null],
        default: null
    },
    mask: {
        type: [String, null],
        default: null
    },
    placeholder: {
        type: [String, null],
        default: null
    },
    modelValue: {
      type: [String, Number, null],
      required: true,
    },
    wrapperClass: {
        type: String,
        default: "field"
    },
    readonly:{
        type: [Boolean, null],
        default: null
    },
    label: {
        type: [String, null],
        default: null
    },
    type: {
        type: [String, null],
        default: "text"
    },
    valid: {
        type: Boolean,
        //Todos son validos antes del submit
        default: true
    },
    validation: {
        type: String,
        default: "Favor de llenar el campo"
    },
    textArea: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    search: {
        type: Boolean,
        default: false
    },
    clear: {
        type: Boolean,
        default: false
    },
    view: {
        type: Boolean,
        default: false
    },
    add: {
        type: Boolean,
        default: false
    },
    barcode: {
        type: Boolean,
        default: false,
    },
    rows: {
      type: Number,
      default: 1
    },
    focuScanner:{
    type:Boolean,
    default:false,
   } },
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  methods: {
    focus() {
      this.$refs.control.$el.focus()
    },

      handleKeyDown(event){
    
    console.log(event)
  if (event.keyCode === 18  ) {

   this.$refs.control.$el.focus()
  }
},
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
    mounted() {
      if(this.focuScanner==true){ window.addEventListener("keydown", this.handleKeyDown);}
   
  },
  beforeUnmount() {
      if(this.focuScanner==true){
    window.removeEventListener("keydown", this.handleKeyDown);
      }
  },
};
</script>

<style>
  .custom-alert .p-tooltip-text {
    background-color: var(--blue-900);
    color: rgb(255, 255, 255);
  }
  .custom-alert.p-tooltip-top .p-tooltip-arrow {
      border-right-color: var(--pblue-900);
  }
</style>