export class BasicRoute {
    constructor(_path, _name, _component, _parent, _label, _toolbar) {
        this.path = _path;
        this.name = _name;
        this.component = _component;
        this.meta = {
            toolbar: _toolbar ?? [],
            breadcrumb: [{
                parent: {
                    title: _parent,
                    to: "/" + _parent.toLowerCase()
                },
                label: _label
            }]
        }
    }
}