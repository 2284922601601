<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <div class="col-2"></div>
        <div class="col-8">
            <div class="card register">
                <Toast />
                <Panel header="Reiniciar contraseña">
                    <div class="grid formgrid p-fluid">
                        <FormInputText v-model="user" :wrapperClass="'field col-12'" :label="'Usuario / E-mail'" />
                        <Button label="Enviar" @click="register" />
                    </div>
                </Panel>
            </div>
        </div>
        <div class="col-2"></div>
    </div>
</template>


<script>
import Loader from "../components/general/Loader.vue";
import FormInputText from "../components/general/FormInputText.vue";
import { ERPUser } from '../models/seguridad/ERPUser';
import { ErrorToast, Toast } from '../utilities/General';
export default {
    components: {
        FormInputText, Loader
    },
    data() {
        return {
            loading: false,
            user: null
        }
    },
    methods: {
        async register() {
            try {
                this.loading = true;
                await new ERPUser().sendReset({
                    user: this.user
                });
                this.$toast.add(new Toast({ summary: "Reinicio de contraseña", detail: "Se ha enviado un correo a la direccion asociada a la cuenta, favor de dirigirse a este correo" , life: 9000}));
            } catch (error) {
                this.$toast.add(new ErrorToast(error, {life: 9000}));
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style>
    .register {
        margin-top: 5rem;
    }

    .p-password-panel	{
        opacity: 1.0;
        background-color: white;
    }

    .p-password-info	{
        opacity: 1.0;
        background-color: white;
    }
</style>