import {
    createRouter,
    createWebHistory
} from 'vue-router';
import { useStorage } from "vue3-storage";
import profile from './pages/Profile.vue';
import invoiceOptions from './pages/InvoiceOptions.vue';

import login from './pages/Login.vue';
import test from './pages/Test.vue';
import register from './pages/Register.vue';
import resetpassword from './pages/ResetPassword.vue';
import completereset from './pages/CompleteReset.vue';
import notFound from './pages/NotFound.vue';
import inventario from './routes/inventario.routes';
import ticket from './routes/ticket.routes';
import seguridad from './routes/seguridad.routes';
import comercial from './routes/comercial.routes';
import developer from './routes/developer.routes';
import cfdi40 from './routes/cfdi40.routes';
import general from './routes/general.routes';
import contabilidad from './routes/contabilidad.routes';
import cxp from './routes/cxp.routes';
import crm from './routes/crm.routes';
import compras from './routes/compras.routes'
// import businesswallet from './routes/businesswallet.routes';

import { db } from './db'

let children = [];
inventario.forEach(r => {
    children.push(r);
});
ticket.forEach(r => {
    children.push(r);
});
seguridad.forEach(r => {
    children.push(r);
});
comercial.forEach(r => {
    children.push(r);
});
developer.forEach(r => {
    children.push(r);
})
cfdi40.forEach(r => {
    children.push(r);
})
general.forEach(r => {
    children.push(r);
})

contabilidad.forEach(r => {
    children.push(r)
})
cxp.forEach(r => {
    children.push(r)
})
crm.forEach(r => {
    children.push(r)
})
compras.forEach(r => {
    children.push(r)
})
children.push({
    path: '/profile/:username',
    name: 'profile',
    props: true,
    meta: {
        breadcrumb: [{
            parent: 'Perfil'
        }],
    },
    component: profile
});
children.push({
    path: '/invoice/options',
    name: 'invoiceoptions',
    meta: {
        breadcrumb: [{
            parent: 'Informacion de Facturacion'
        }],
    },
    component: invoiceOptions
});

import admin from './components/Admin.vue';
import { BASIC_MENUS } from './utilities/BASIC_MENUS';

async function asyncCreateRouter () {
    const routes = [
        {
            path: '/login',
            name: 'login',
            component: login
        },
        {
            path: '/test',
            name: 'test',
            component: test
        },
        {
            path: '/completereset/:id',
            name: 'completereset',
            component: completereset,
            props: true,
        },
        {
            path: '/register/:id',
            name: 'register',
            component: register,
            props: true,
        },
        {
            path: '/resetpassword',
            name: 'resetpassword',
            component: resetpassword    
        },
        {
            path: '/',
            name: 'admin',
            component: admin,
            meta: {
                breadcrumb: [{
                    parent: 'Inicio'
                }],
            },
            children: children
        },
        {
            path: '/error',
            name: 'error',
            component: () => import('./pages/Error.vue')
        },
        {
            path: '/access',
            name: 'access',
            component: () => import('./pages/Access.vue')
        }
    ];
    
    routes.push({
        path: '/:pathMatch(.*)*',
        name: '404',
        component: notFound
    });
    
    const router = createRouter({
        history: createWebHistory(),
        routes,
        scrollBehavior() {
            return {
                left: 0,
                top: 0
            };
        }
    });
    
    //* AQUI MODIFICAMOS DEPENDIENDO DEL TIPO DE CLIENTE AL QUE HAREMOS LA APP */
    if(process.env.VUE_APP_CLIENT == "tootlesoft" 
    || process.env.VUE_APP_CLIENT == "players_palace") {
        let exports = await import('./routes/businesswallet.routes')
        exports.default.forEach(r => {
            router.addRoute('admin', r);
        })
    }
    
    router.beforeEach(async (to) => {
        //* Verificamos si necesitan validacion las rutas
        if (BASIC_MENUS[to.name] ?? true) {
            const storage = useStorage();
            let user = storage.getStorageSync("usuario");
            //* Checamos la session
            if (!user) 
                return { name: 'login' }
            else if (to.name == 'login')
                return { name: 'admin' }
            else if (to.name != "admin") 
            {
                let menu = (await db.menus.toArray()).filter(x => x.to);
                let finded = menu.find(x => x.to.toLowerCase() == to.fullPath.toLowerCase());
                if (!finded && to.fullPath.split('/').length > 2) 
                    return { name: 'access' }
            }
    
        }
    })

    return router;
}


export default asyncCreateRouter;