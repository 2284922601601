import { BasicRoute } from './BasicRoute';
import { ParentRoute } from "../routes/ParentRoute";

export default [
    new ParentRoute('/crm', 'CRM', () => import('../components/templates/ParentDirectory.vue')),
    new BasicRoute('/crm/catalogos/prospectos','crmprospectos',() => import('../pages/CRM/Catalogos/Prospecto.vue'), 'CRM', 'Prospectos'),
    new BasicRoute('/crm/aplicaciones/cotizacion','crmcotizacion',() => import('../pages/CRM/Aplicaciones/Quotation.vue'), 'CRM', 'Cotizacion'),
    new BasicRoute('/crm/aplicaciones/admincotizacion','crmadmincotizacion',() => import('../pages/CRM/Aplicaciones/QuotationManager.vue'), 'CRM', 'Administrar Cotizaciones'),
    new BasicRoute('/crm/aplicaciones/tablerocotizacion','crmtablerocotizacion',() => import('../pages/CRM/Aplicaciones/TableroCotizacion.vue'), 'CRM', 'Tablero de Cotizaciones'),
]

