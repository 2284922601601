import { BasicRoute } from "../routes/BasicRoute";
import { ParentRoute } from "../routes/ParentRoute";

export default [
    new ParentRoute('/contabilidad', 'Contabilidad', () => import('../components/templates/ParentDirectory.vue')),
    new BasicRoute('/contabilidad/catalogos/tipopoliza','contabilidadtipopoliza',() => import('../pages/Contabilidad/Catalogos/AccountingPolicyType.vue'),'Contabilidad', 'Tipo Poliza Contable'),
    new BasicRoute('/contabilidad/catalogos/tipogrupocontable','contabilidadtipogrupocontable',() => import('../pages/Contabilidad/Catalogos/AccountingGroupType.vue'),'Contabilidad', 'Tipo Grupo Contable'),
    new BasicRoute('/contabilidad/catalogos/grupocontable','contabilidadgrupocontable',() => import('../pages/Contabilidad/Catalogos/AccountingGroup.vue'),'Contabilidad', 'Grupo Contable'),
    new BasicRoute('/contabilidad/catalogos/centrocosto','contabilidadcentrocosto',() => import('../pages/Contabilidad/Catalogos/AccountingCostCenter.vue'),'Contabilidad', 'Centro de Costo'),
    new BasicRoute('/contabilidad/catalogos/cuentacontable','contabilidadcuentacontable',() => import('../pages/Contabilidad/Catalogos/AccountingLedgerAccount.vue'),'Contabilidad', 'Cuenta Contable'),
    new BasicRoute('/contabilidad/aplicaciones/poliza','contabilidadpoliza',() => import('../pages/Contabilidad/Aplicaciones/AccountingPolicy.vue'),'Contabilidad', 'Poliza Contable'),
    new BasicRoute('/contabilidad/aplicaciones/administrarpoliza','contabilidadadministrarpoliza',() => import('../pages/Contabilidad/Aplicaciones/AccountingPolicyManagement.vue'),'Contabilidad', 'Administrar Polizas Contables'),
    new BasicRoute('/contabilidad/aplicaciones/cierre','contabilidadcierre',() => import('../pages/Contabilidad/Aplicaciones/AccountingMonthlyClosure.vue'),'Contabilidad', 'Cierre de Mes'),
    new BasicRoute('/contabilidad/aplicaciones/configuracionpoliza','configuracionpoliza',() => import('../pages/Contabilidad/Aplicaciones/AccountingPolicyConfiguration.vue'),'Contabilidad', 'Configuracion de Polizas Automaticas'),
    new BasicRoute('/contabilidad/reportes/balance','contabilidadbalance',() => import('../pages/Contabilidad/Reportes/AccountingGeneralBalance.vue'),'Contabilidad', 'Balance General'),
]

