import {
    Abstract
} from './Abstract';

import axios from "axios";

export class CustomerBankAccount extends Abstract {
    constructor(session) {
        super('comercial/CustomerBankAccount', session);
        this.id_customer = null;
        this.id_bank = null;
        this.interbank_key = null;
    }
    async getAll(params) {
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        let response = await axios.get(this.controller + "/getAll" + query ,{
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
            }
        });
        return response.data;
    }
}