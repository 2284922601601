<template>
	<div class="layout-topbar">
        <OverlayPanel ref="opChat">
            <p v-if="reminders.length == 0">No hay recordatorios</p>
            <div class="grid p-fluid">
                <div v-for="i in 3" :key="i" class="col-12">
                    <div class="card">
                        <div><Avatar icon="pi pi-user" /><span style="margin-left: .5rem;">John Doe</span></div>
                        <div style="margin-top: .25rem"><Button icon="pi pi-search" class="p-button-rounded p-button-success" /> <span style="margin-left: .5rem;">asdkoasdko</span></div> 
                    </div>
                </div>
            </div>
        </OverlayPanel>
        <div class="layout-topbar-left">
            <a class="topbar-menu-button" @click="onMenuButtonClick($event)">
                <i class="pi pi-bars"></i>
            </a>

            <a style="cursor: pointer;" @click="home()" class="logo">
                <img :src="'/layout/images/logo-'+ (colorScheme === 'light' ? 'dark' : 'light') + '.png'">
            </a>

            <a style="cursor: pointer;" @click="home()">
                <img :src="'/layout/images/appname-'+ (colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="app-name"/>
            </a>
        </div>

        <AppMenu :model="items" :menuMode="menuMode" :colorScheme="colorScheme" :menuActive="menuActive" :activeInlineProfile="activeInlineProfile"
            @sidebar-mouse-over="onSidebarMouseOver" @sidebar-mouse-leave="onSidebarMouseLeave" @toggle-menu="onToggleMenu" @change-inlinemenu="onChangeActiveInlineMenu" 
            @menu-click="onMenuClick" @root-menuitem-click="onRootMenuItemClick" @menuitem-click="onMenuItemClick" />

        <div class="layout-topbar-right">
            <ul class="layout-topbar-right-items">
                <li id="profile" class="profile-item" :class="{'active-topmenuitem': topbarMenuActive}">
                    <a href="#" @click="onTopbarItemClick($event, 'profile')">
                        <img @error="setAltImg" :src="profileImage">
                    </a>

                    <ul class="fadeInDown">
                        <li role="menuitem">
                            <a>
                                <span>{{session.branch_name}}</span>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a href="#" @click="onTopbarSubItemClick($event, 'profile')">
                                <i class="pi pi-fw pi-user"></i>
                                <span>Perfil</span>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a href="#" @click="onTopbarSubItemClick($event, 'acceses')">
                                <i class="pi pi-fw pi-cog"></i>
                                <span>Accesos</span>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a href="#" @click="onTopbarSubItemClick($event, 'logout')">
                                <i class="pi pi-fw pi-sign-out"></i>
                                <span>Salir</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#">
                        <i class="topbar-icon pi pi-fw pi-bell"></i>
                        <!-- <span class="topbar-badge">2</span> -->
                        <span class="topbar-item-name">Notifications</span>
                    </a>
                </li>
                <li>
                    <a @click="openChat">
                        <i class="topbar-icon pi pi-fw pi-comment"></i>
                        <!-- <span class="topbar-badge">5</span> -->
                        <span class="topbar-item-name">Messages</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Session from './mixins/rootSessionMixin'
import AppMenu from './AppMenu.vue';
export default {
    mixins: [Session],
	emits: ['topbar-subitem-click','menu-click', 'menuitem-click', 'root-menuitem-click', 'menubutton-click', 'sidebar-mouse-over', 'sidebar-mouse-leave', 'toggle-menu', 'change-inlinemenu', 'topbar-item-click'],
	data() {
		return {
			activeTopbarItem: null,
            profileImage: null,
            reminders: [],
            error_count: 0,
            publicPath: process.env.BASE_URL,

		}
	},
	props: {
        items: Array,
		menuMode: String,
		colorScheme: String,
        topbarMenuActive: {
            type: Boolean,
            default: false
        },
        menuActive: {
            type: Boolean,
            default: false
        },
        activeInlineProfile: {
            type: Boolean,
            default: false
        }
	},
	methods: {
        setAltImg(event) { 
            event.target.src = this.publicPath + 'images/general/avatar.jpg';
            this.error_count++;
        },
        openChat(event) { 
            this.$refs.opChat.toggle(event);
        },
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
		onMenuButtonClick(event) {
			this.$emit('menubutton-click', event);
		},
		onTopbarItemClick(event, item) {
            this.$emit('topbar-item-click', event, item);	
			event.preventDefault();
		},
		onTopbarSubItemClick(event, item) {
            this.$emit('topbar-subitem-click', event, item);	
			event.preventDefault();
		},
        onSidebarMouseOver() {
            this.$emit('sidebar-mouse-over');
        },
        onSidebarMouseLeave() {
            this.$emit('sidebar-mouse-leave');
        },
        onToggleMenu(event) {
            this.$emit('toggle-menu', event);
        },
        onChangeActiveInlineMenu() {
            this.$emit('change-inlinemenu');
        },
		isOverlay() {
			return this.menuMode === 'overlay';
		},
        home() {
            this.$router.push('/')
        }
	},
    mounted() {
        this.profileImage = this.$config.api_route + 'security/ERPUser/image/' + this.session.usuario;
    },
	components: {
		AppMenu
	}
}
</script>
